<template>
  <div
    v-if="show"
    ref="edit-deposit-modal"
    class="modal modal__right"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <slot name="modal-head"> </slot>
            <slot name="modal-title">
              <h5 class="modal__head__title text--capital">add new holiday</h5>
            </slot>
          </div>
          <div class="modal__body">
            <slot name="modal-body"> </slot>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditModal",
  props: {
    show: Boolean,
    close: Function,
  },
};
</script>
