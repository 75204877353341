<template>
  <!-- <div class="page__content"> -->
  <!-- <KTAside></KTAside> -->
  <main class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />

    <div class="page__body">
      <div class="page__body__header">
        <div class="row align-items-center justify-content-between">
          <div class="col-4">
            <label
              for="text"
              class="has-checkbox page__body__header__label text--capital"
              >Show deactivated products
              <input
                type="checkbox"
                id="text"
                v-model="deactivatedProduct"
                @click="switchShowDeactivated()"
                class="form-"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="col-5 ml-auto">
            <div class="row align-items-center justify-content-between">
              <div class="col-5">
                <!-- <button
                    type="button"
                    class="w-100 button page__body__header__button button--grey text--capital"
                  >
                    <span class="d-inline-flex mr-2">filter by</span>
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </button> -->
                <b-dropdown
                  :html="filter_by_label"
                  no-caret
                  class="m-2 page__body__header__button dropdown--button-grey w-100"
                  v-model="sort"
                >
                  <b-dropdown-item
                    v-for="item in cats"
                    :key="item.value"
                    v-bind:value="item.value"
                    @click="filterByCategory(item.value, item.text)"
                    >{{ item.text }}</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <div class="col-7" v-if="userCanCreateProduct">
                <button
                  type="button"
                  class="button w-100 page__body__header__button button--black text--capital"
                  @click="openCreateModal()"
                >
                  <span class="d-inline-flex mr-2">new deposit product</span>
                  <ion-icon name="add-outline" class="text--regular"></ion-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-5">
            <div class="form__item">
              <input
                type="text"
                class="input form__input form__input--lg"
                v-model="searchProductName"
                @input="performSearch"
                id="searchProduct"
                placeholder="Search for Product Name"
                style="height: 40px"
              />
            </div>
          </div>
          <!-- <div class="col-2">
            <button
              type="button"
              class="
                button
                w-100
                page__body__header__button
                button--black
                text--capital
              "
              @click="performSearch"
            >
              <span class="d-inline-flex mr-2">search</span>
            </button>
          </div> -->
        </div>
      </div>

      <div
        role="alert"
        v-bind:class="{ show: allErrors.length }"
        class="alert fade alert-danger"
        v-show="allErrors.data"
      >
        <div class="alert-text">
          <span v-for="(error, i) in allErrors.data" :key="i">
            {{ error }}
          </span>
        </div>
      </div>

      <table class="customtable w-100">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 20%"
            >
              product name
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              product ID
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 20%"
            >
              product type
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              last modified
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              status
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              actions
            </th>
          </tr>
        </thead>
        <tbody v-if="!products.data">
          <tr>
            <td colspan="4">
              <div class="d-flex align-items-center justify-content-center p5">
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span
                  class="text--black text--600 text--medium d-inline-flex ml-4"
                  >Please wait</span
                >
              </div>
            </td>
          </tr>
        </tbody>

        <tbody v-else-if="products.data != ''">
          <tr v-for="(allDeposit, i) in products.data" :key="i">
            <td>
              <span
                class="table__body__text table__text text--capital"
                style="word-break: break-word"
                >{{ allDeposit.productName }}</span
              >
            </td>
            <td>
              <span
                class="table__body__text table__text text--capital"
                style="word-break: break-word"
                >{{ allDeposit.productID }}</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                allDeposit.productType
              }}</span>
            </td>
            <td v-if="allDeposit.lastModifiedDate === ''">
              <span class="table__body__text table__text text--capital"> </span>
            </td>
            <td v-else>
              <span class="table__body__text table__text text--capital">{{
                allDeposit.lastModifiedDate | moment
              }}</span>
            </td>
            <td>
              <span
                class="table__body__text table__text text--capital text--success"
                v-if="allDeposit.productState"
                >Active</span
              >
              <span
                class="table__body__text table__text text--capital text--danger"
                v-else
                >Deactivated</span
              >
            </td>

            <td style="padding-right: 0 !important">
              <div class="align-self-center dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  action
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    @click="getProductByID(allDeposit)"
                    v-if="userCanEditDepositProduct"
                    >edit</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#confirmModal"
                    @click="deactivate(allDeposit, true)"
                    v-if="allDeposit.productState && userCanDeactivateProduct"
                    >deactivate</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#confirmModal"
                    @click="activate(allDeposit)"
                    v-if="userCanActivateProduct && !allDeposit.productState"
                    >activate</a
                  >

                  <a
                    class="dropdown-item"
                    v-if="userCanCreateProduct"
                    @click="copyDepositProduct(allDeposit)"
                    >Copy product</a
                  >

                  <a
                    class="dropdown-item"
                    v-if="userCanDeleteProduct"
                    @click="deactivate(allDeposit, false)"
                    >Delete</a
                  >
                </div>
              </div>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td colspan="12">
              <div
                class="d-flex flex-column align-items-center justify-content-center p5"
              >
                <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                <span
                  class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >No data available</span
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <Pagination
        :allItems="products"
        :size="size"
        @update-created-task="getUpdatedDeposits"
        @change-page-index="changePageIndex"
      />
    </div>

    <SideModal
      :show="showCreateModal"
      :close="closeCreateModal"
      @close="closeCreateModal"
    >
      <template v-slot:modal-title>
        <h5 class="modal__head__title text--capital">
          Create New Deposit Product
        </h5>
      </template>
      <template v-slot:modal-body>
        <form>
          <div class="row">
            <div class="col-6">
              <BaseInput
                label="Deposit Product Name"
                v-model.trim="form.productName"
                type="text"
                placeholder="Enter Product Name"
              />
              <div class="error" v-if="!$v.form.productName.required">
                <span class="text-danger"
                  >Deposit Product name is required</span
                >
              </div>
            </div>

            <div class="col-6">
              <BaseInput
                label="Deposit Product ID"
                v-model.trim="form.productID"
                type="text"
                placeholder="Enter ID"
              />
              <div class="error" v-if="!$v.form.productID.required">
                <span class="text-danger">Deposit Product ID is required</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">Deposit Product Category</label>
                <select
                  class="select select--lg"
                  required
                  v-model.trim="form.productCategory"
                >
                  <option value="" disabled selected>Select a category</option>
                  <option
                    v-bind:key="category.id"
                    v-for="category in allproductcategory.data"
                    :id="category.id"
                    :value="category"
                  >
                    {{ category }}
                  </option>
                </select>
              </div>
              <div class="error" v-if="!$v.form.productCategory.required">
                <span class="text-danger">Product category is required</span>
              </div>
            </div>
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">Deposit Product Type</label>
                <select
                  class="select select--lg"
                  required
                  v-model.trim="form.productType"
                  @change="checkProductType()"
                >
                  <option value="" disabled selected>
                    Select Product Type
                  </option>
                  <option
                    v-bind:key="product.id"
                    v-for="product in allProductTypes.data"
                    :id="product.id"
                    :value="product"
                    :true-value="product"
                  >
                    {{ product }}
                  </option>
                </select>
              </div>
              <div class="error" v-if="!$v.form.productType.required">
                <span class="text-danger"
                  >Deposit Product Type is required</span
                >
              </div>
            </div>
          </div>

          <label class="form__label text--black text--capital mb-3"
            >state</label
          >
          <div>
            <label
              for="state"
              class="has-checkbox page__body__header__label text--capital mb-3 text--black"
              >active
              <input
                type="checkbox"
                id="state"
                class="form-"
                v-model="form.productState"
                :checked="form.productState"
              />
              <span class="checkmark"></span>
            </label>
          </div>

          <ul id="accordion">
            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#productDescription"
                aria-expanded="false"
                aria-controls="productDescription"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  product description
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div
                id="productDescription"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <div class="form__item">
                    <div id="content-container">
                      <TextArea
                        label="Description"
                        placeholder="Product Description"
                        v-model.trim="form.productDescription"
                        cols="5"
                        rows="5"
                      />
                      <div
                        class="error"
                        v-if="!$v.form.productDescription.required"
                      >
                        <span class="text-danger"
                          >Product description is required</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#productAvailability"
                aria-expanded="false"
                aria-controls="productAvailability"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  product availability (At least one option must be checked)
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>

              <div
                id="productAvailability"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <label class="form__label text--grey text--capital mb-3"
                    >available to</label
                  >
                  <div class="d-flex flex-column">
                    <label
                      for="Customers"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >Customer
                      <input
                        type="checkbox"
                        v-model="form.availablity"
                        id="Customers"
                        value="Customers"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label
                      for="Groups"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >Groups
                      <input
                        type="checkbox"
                        v-model="form.availablity"
                        id="Groups"
                        value="Groups"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label
                      for="All_Branches"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >All Branches
                      <input
                        type="checkbox"
                        id="All_Branches"
                        value="All Branches"
                        v-model="form.forAllBranches"
                        @click="addBranches($event)"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <transition name="slide-fade" appear>
                    <div class="form__item" v-if="branches === true">
                      <label
                        class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                        >Branches</label
                      >
                      <multiselect
                        v-model="allBranches"
                        tag-placeholder="Add this as new tag"
                        placeholder="Search or add a Branch"
                        label="branchName"
                        track-by="branchName"
                        :options="options"
                        :multiple="true"
                        :taggable="true"
                        @tag="addTag"
                        @input="updateBranches"
                      ></multiselect>
                    </div>
                  </transition>
                </div>
              </div>
            </div>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#accountSettings"
                aria-expanded="false"
                aria-controls="accountSettings"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  new account settings
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div
                id="accountSettings"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">ID Type</label>
                        <select
                          class="select select--lg"
                          required
                          v-model.trim="form.idType"
                          @change="newAccountSettings"
                        >
                          <option value="" disabled selected>
                            Select ID Type
                          </option>
                          <option
                            v-bind:key="IDType.id"
                            v-for="IDType in allIDTypes.data"
                          >
                            {{ IDType }}
                          </option>
                        </select>
                      </div>
                      <div class="error" v-if="!$v.form.idType.required">
                        <span class="text-danger">ID Type is required</span>
                      </div>
                    </div>
                    <transition name="slide-fade" appear>
                      <div class="col-6" v-show="AccountSetting">
                        <BaseInput
                          label="Starting From"
                          v-model.number="form.idTemplate"
                          type="number"
                          min="0"
                          placeholder="Starting From"
                        />
                      </div>
                    </transition>
                    <transition name="slide-fade" appear>
                      <div class="col-6" v-show="template">
                        <BaseInput
                          label="Using Template"
                          v-model.trim="form.idTemplate"
                          :info="'The symbol # represent Numbers (Ex. 1,2,3...)\nThe symbol @ represent Alphabets (Ex. ABCabc)\nThe symbol $ represent AlphaNumeric (Ex. ABCabc123)'"
                          type="text"
                          placeholder="Enter Template"
                        />
                        <div class="error" v-if="!$v.form.idTemplate.required">
                          <span class="text-danger"
                            >ID template is required</span
                          >
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#currencies"
                aria-expanded="false"
                aria-controls="currencies"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  currencies
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="currencies" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="form__item">
                    <label class="form__label">currency</label>
                    <select
                      class="select select--lg"
                      required
                      v-model="form.currency"
                    >
                      <option value="" disabled selected>
                        Select any currency
                      </option>
                      <option
                        v-bind:key="currency.id"
                        v-for="currency in sortedCurrencies"
                        :id="currency.currencyCode"
                        :value="currency.currencyCode"
                      >
                        {{ currency.currencyCode }}
                      </option>
                    </select>
                  </div>
                  <div class="error" v-if="!$v.form.currency.required">
                    <span class="text-danger">Currency is required</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#openbalance"
                aria-expanded="false"
                aria-controls="openbalance"
              >
                <h5
                  class="
                    w-100
                    mb-0
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  opening balance
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="openbalance" class="collapse" data-parent="#accordion">
                <div>
                  <p
                    class="text--primary form__item_text text--regular"
                  >
                    Opening Balance Constraint
                  </p>
                  <div class="row">
                    <div class="col-4">
                      <div class="form__item">
                        <label class="form__label">Default</label>
                        <input
                          type="text"
                          class="input form__input form__input--lg"
                          placeholder="Default"
                          v-model="defaultOpeningBalance"
                          @input="controlValue($event)"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form__item">
                        <label class="form__label">Minimum</label>
                        <input
                          type="text"
                          class="input form__input form__input--lg"
                          placeholder="Enter minimum"
                          v-model="minOpeningBalance"
                          @input="controlValue('minGracePeriod', $event)"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form__item">
                        <label class="form__label">Maximum</label>
                        <input
                          type="text"
                          class="input form__input form__input--lg"
                          placeholder="Enter Maximum"
                          v-model="maxOpeningBalance"
                          @input="controlValue('minGracePeriod', $event)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#interestRate"
                aria-expanded="false"
                aria-controls="interestRate"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  interest rate
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="interestRate" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="form__item">
                    <label
                      for="interestpaid"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >Interest paid into account
                      <input
                        type="checkbox"
                        id="interestpaid"
                        class="form-"
                        v-model="form.interestPaidIntoAccount"
                        :checked="form.interestPaidIntoAccount"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <transition name="slide-fade" appear>
                    <div v-if="form.interestPaidIntoAccount === true">
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label"
                              >Interest Rate Terms</label
                            >
                            <select
                              class="select select--lg"
                              required
                              v-model.trim="form.interestRateTerms"
                              @change="interestHandler"
                            >
                              <option value="" disabled selected>
                                Select Interest Calculation
                              </option>
                              <option
                                v-bind:key="rate.id"
                                v-for="rate in allInterestRate.data"
                              >
                                {{ rate }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="error"
                            v-if="!$v.form.interestRateTerms.required"
                          >
                            <span class="text-danger"
                              >Interest rate term is required</span
                            >
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label"
                              >How is the Interest rate charged?</label
                            >
                            <select
                              class="select select--lg"
                              required
                              v-model.trim="form.interestChargeMethod"
                              @change="percentHandler()"
                            >
                              <option value="" disabled selected>
                                Select Accrued Interest
                              </option>
                              <option
                                v-bind:key="interest.id"
                                v-for="interest in allInterestCharge.data"
                              >
                                {{ interest }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="error"
                            v-if="!$v.form.interestChargeMethod.required"
                          >
                            <span class="text-danger"
                              >Interest rate term is required</span
                            >
                          </div>
                        </div>
                        <div class="col-6" v-show="days">
                          <div class="form__item">
                            <BaseInput
                              label="Percent Per x Days"
                              v-model.number="form.interestPerDays"
                              type="number"
                              placeholder="Interest per x days"
                            />
                          </div>
                        </div>
                      </div>

                      <div v-show="fixedTerm">
                        <p
                          class="text--primary form__item_text text--regular mb-5"
                        >
                          Interest Rate Constraints (%)
                        </p>
                        <div class="row">
                          <div class="col-6">
                            <BaseInput
                              label="Defaults"
                              v-model.number="
                                form.defaultInterestRateConstraints
                              "
                              type="text"
                              placeholder="Defaults"
                            />
                          </div>
                          <div class="col-6">
                            <BaseInput
                              label="Minimum"
                              v-model.number="form.minInterestRateConstraints"
                              type="number"
                              placeholder="Enter minimum"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <BaseInput
                              label="Maximum"
                              v-model.number="form.maxInterestRateConstraints"
                              type="text"
                              placeholder="Enter Maximum"
                            />
                          </div>
                        </div>
                      </div>

                      <div v-show="balancedTier">
                        <div
                          class="form__item p-5 border border-radius"
                          v-for="(balanceTier, counter) in form.balanceTiers"
                          :key="balanceTier.id"
                        >
                          <p
                            class="text--primary form__item_text text--regular mb-5"
                          >
                            Tiered Per Balance {{ counter + 1 }}
                          </p>
                          <div class="row">
                            <div class="col-6">
                              <BaseInput
                                label="From"
                                v-model.number="balanceTier.fromTier"
                                type="number"
                                min="0.00"
                                placeholder="0.00"
                                value="0.0"
                                disabled
                                step=".01"
                              />
                            </div>
                            <div class="col-6">
                              <BaseInput
                                label="To"
                                :value="inputCurrencyFormat(balanceTier.toTier)"
                                type="text"
                                placeholder="0.00"
                                @keyup="
                                  productBalanceTierChange(
                                    $event,
                                    balanceTier.id
                                  )
                                "
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <div class="form__item">
                                <label class="form__label">
                                  {{ form.interestChargeMethod || "%" }}
                                </label>
                                <input
                                  type="number"
                                  placeholder="0.00"
                                  min="0"
                                  class="input form__input form__input--lg"
                                  v-model.number="balanceTier.interestTierValue"
                                />
                              </div>
                            </div>
                          </div>
                          <button
                            type="button"
                            class="button ml-auto page__body__header__button button--cancel text--capital"
                            @click="deleteBalanceTiers(counter)"
                          >
                            <span class="d-inline-flex">delete </span>
                          </button>
                        </div>
                        <div class="form__item">
                          <button
                            type="button"
                            class="button page__body__header__button button--grey text--capital"
                            @click="addBalanceTiers"
                          >
                            <ion-icon
                              name="add-outline"
                              class="text--regular"
                            ></ion-icon>
                            <span class="d-inline-flex ml-2"
                              >add balance tiers</span
                            >
                          </button>
                        </div>
                      </div>

                      <div v-show="periodTiered">
                        <div
                          class="form__item p-5 border border-radius"
                          v-for="(periodTier, counter) in form.periodTiers"
                          :key="counter"
                        >
                          <p
                            class="text--primary form__item_text text--regular mb-5"
                          >
                            Tiered Per Period {{ counter + 1 }}
                          </p>
                          <div class="row">
                            <div class="col-6">
                              <BaseInput
                                label="From"
                                v-model.number="periodTier.fromTier"
                                type="number"
                                min="0"
                                placeholder="0"
                                disabled
                              />
                            </div>
                            <div class="col-6">
                              <BaseInput
                                label="To"
                                v-model.number="periodTier.toTier"
                                type="number"
                                min="0"
                                placeholder="0"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <div class="form__item">
                                <label class="form__label">
                                  {{ form.interestChargeMethod }}
                                  <span style="font-style: italic"
                                    >Calculated by number of days</span
                                  >
                                </label>
                                <input
                                  type="number"
                                  min="0"
                                  placeholder="Enter Days"
                                  class="input form__input form__input--lg"
                                  v-model="periodTier.interestTierValue"
                                />
                              </div>
                            </div>
                          </div>
                          <button
                            type="button"
                            class="button ml-auto page__body__header__button button--cancel text--capital"
                            @click="deletePeriodTiers(counter)"
                          >
                            <span class="d-inline-flex">delete </span>
                          </button>
                        </div>
                        <div class="form__item">
                          <button
                            type="button"
                            class="button page__body__header__button button--grey text--capital"
                            @click="addPeriodTiers"
                          >
                            <ion-icon
                              name="add-outline"
                              class="text--regular"
                            ></ion-icon>
                            <span class="d-inline-flex ml-2"
                              >add period tiers</span
                            >
                          </button>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label"
                              >What Account Balance is Used for
                              Calculations?</label
                            >
                            <select
                              class="select select--lg"
                              required
                              v-model.trim="form.calculationAccountBalance"
                              @change="changeBalanceCalculation()"
                            >
                              <option value="" disabled selected>
                                Select account
                              </option>
                              <option
                                v-bind:key="account_balance.id"
                                v-for="account_balance in allAccountBalanceCalculation.data"
                              >
                                {{ account_balance }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="error"
                            v-if="!$v.form.calculationAccountBalance.required"
                          >
                            <span class="text-danger"
                              >Calculation account balance is required</span
                            >
                          </div>
                        </div>
                        <div class="col-6" v-show="balanceCalc">
                          <div class="form__item">
                            <label class="form__label"
                              >Maximum Balance used for Interest
                              Calculation</label
                            >
                            <input
                              type="number"
                              min="0"
                              placeholder="Enter Days"
                              class="input form__input form__input--lg"
                              v-model.number="
                                form.maxInterestCalculationBalance
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label"
                              >When is Interest Paid Into the Account?</label
                            >
                            <select
                              class="select select--lg"
                              required
                              v-model.trim="form.interestPaymentPeriod"
                              @change="handleChange"
                            >
                              <option value="" disabled selected>
                                Select days
                              </option>
                              <option
                                :key="account_interests.id"
                                v-for="account_interests in allAccountInterests.data"
                              >
                                {{ account_interests }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="error"
                            v-if="!$v.form.interestPaymentPeriod.required"
                          >
                            <span class="text-danger"
                              >Interest payment period is required</span
                            >
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Days In Year</label>
                            <select
                              class="select select--lg"
                              required
                              v-model="form.interestDaysInYears"
                            >
                              <option value="" disabled selected>
                                Select Days In Year
                              </option>
                              <option
                                v-bind:key="days.id"
                                v-for="days in allDaysInYears.data"
                              >
                                {{ days }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <!-- <div class="col-6" v-show="monthly">
                            <div class="form__item">
                              <label class="form__label">Month</label>
                              <select
                                class="select select--lg"
                                required
                                v-model="form.months"
                              >
                                <option value="" disabled selected
                                  >Select Month</option
                                >
                                <option
                                  v-bind:key="months.id"
                                  v-for="months in allMonths.data"
                                >
                                  {{ months }}
                                </option>
                              </select>
                            </div>
                          </div> -->
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>

            <transition name="slide-fade" appear>
              <div class="modal__accordion" v-if="depProduct === true">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#depositandwithdrawals"
                  aria-expanded="false"
                  aria-controls="depositandwithdrawals"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    deposit & withdrawals
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="depositandwithdrawals"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="row align-items-center">
                      <div class="col-6">
                        <div class="form__item">
                          <BaseInput
                            label="Recommended Deposit Amount"
                            v-model.number="form.defaultDepositAmount"
                            type="number"
                            min="0"
                            placeholder="Recommended Deposit Amount"
                          />
                        </div>
                      </div>
                      <div class="col-6" v-show="maxDepo">
                        <div class="form__item">
                          <BaseInput
                            label="Single Withdrawal Limit"
                            v-model.number="form.maxWithdrawalAmount"
                            type="number"
                            min="0"
                            placeholder="Single Withdrawal Limit"
                          />
                        </div>
                      </div>
                      <div class="col-6" v-show="maxDepo">
                        <div class="form__item">
                          <BaseInput
                            label="Daily Withdrawal Limit"
                            v-model.number="form.dailyWithdrawalLimit"
                            type="number"
                            min="0"
                            placeholder="Daily Withdrawal Limit"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <transition name="slide-fade" appear>
              <div class="modal__accordion" v-if="openBalance === true">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#openingbalance"
                  aria-expanded="false"
                  aria-controls="openingbalance"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Opening Balance
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="openingbalance"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="row align-items-center">
                      <div class="col-6">
                        <div class="form__item">
                          <BaseInput
                            label="Default Opening Balance"
                            v-model.number="form.defaultOpeningBalance"
                            type="number"
                            min="0"
                            placeholder="Default Opening Balance"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <BaseInput
                            label="Minimum Opening Balance"
                            v-model.number="form.minOpeningBalance"
                            type="number"
                            placeholder="Minimum Opening Balance"
                            min="0"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <BaseInput
                            label="Maximum Opening Balance"
                            v-model.number="form.maxOpeningBalance"
                            type="number"
                            min="0"
                            placeholder="Maximum Opening Balance"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <transition name="slide-fade" appear>
              <div class="modal__accordion" v-if="productType === true">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#termlength"
                  aria-expanded="false"
                  aria-controls="termlength"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Term Length
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="termlength" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="row align-items-center">
                      <div class="col-6">
                        <div class="form__item">
                          <BaseInput
                            label="Default Term Length"
                            v-model.number="form.defaultTermLength"
                            type="number"
                            min="0"
                            placeholder="Default Term Length"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <BaseInput
                            label="Minimum Term Length"
                            v-model.number="form.minTermLength"
                            type="number"
                            min="0"
                            placeholder="Minimum Term Length"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <BaseInput
                            label="Maximum Term Length"
                            v-model.number="form.maxTermLength"
                            type="number"
                            min="0"
                            placeholder="Maximum Term Length"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Term Unit</label>
                          <select
                            class="select select--lg"
                            required
                            v-model="form.termUnit"
                          >
                            <option value="" disabled selected>
                              Select Term unit
                            </option>
                            <option
                              v-bind:key="alltermUnit.id"
                              v-for="alltermUnit in alltermUnits.data"
                              :id="alltermUnit.id"
                              :value="alltermUnit"
                            >
                              {{ alltermUnit }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#internalcontrol"
                aria-expanded="false"
                aria-controls="internalcontrol"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Internal Control
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div
                id="internalcontrol"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <div class="row align-items-center">
                    <div class="col-6">
                      <div class="form__item">
                        <label
                          for="autodormant"
                          class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                          >Automatically set account as dormant
                          <input
                            type="checkbox"
                            id="autodormant"
                            class="form-"
                            v-model="form.autoDormant"
                            :checked="form.autoDormant"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <transition name="slide-fade" appear>
                      <div class="col-6" v-if="form.autoDormant">
                        <div class="form__item">
                          <BaseInput
                            label="Automatically set account as dormant after x days"
                            v-model.number="form.autoDormantAfter"
                            type="number"
                            min="0"
                            placeholder="Days"
                          />
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#productFees"
                aria-expanded="false"
                aria-controls="productFees"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  product fees
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="productFees" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="form__item">
                    <label
                      for="allowArbitaryFees"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >Allow Arbitrary Fee
                      <input
                        type="checkbox"
                        id="allowArbitaryFees"
                        class="form-"
                        v-model="form.allowArbitaryFees"
                        :checked="form.allowArbitaryFees"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form__item">
                    <label
                      for="showInactiveFees"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >Show Inactive Fees
                      <input
                        type="checkbox"
                        id="showInactiveFees"
                        class="form-"
                        v-model="form.showInactiveFees"
                        :checked="form.showInactiveFees"
                        @click="showOnlyInactive('create')"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div v-if="!form.showInactiveFees">
                    <div
                      v-for="(fee, counter) in showCreateInactiveFeeData"
                      :key="counter"
                    >
                      <div class="form__item p-5 border border-radius">
                        <div class="row">
                          <div class="col-12">
                            <label
                              :for="counter"
                              class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                              >Activate
                              <input
                                type="checkbox"
                                :id="counter"
                                class="form-"
                                v-model="fee.activate"
                                @click="updateActivate(fee, 'create')"
                                :value="counter"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                          <div class="col-6">
                            <BaseInput
                              label="Name"
                              v-model="fee.feeName"
                              type="text"
                              placeholder="Enter name"
                            />
                          </div>
                          <div class="col-6">
                            <div class="form__item form__item--label">
                              <label class="form__label">Type</label>
                              <select
                                class="select select--lg"
                                required
                                v-model="fee.feeType"
                                @change="feeChange()"
                              >
                                <option value="" disabled selected>
                                  Select type
                                </option>
                                <option
                                  :key="feeTypes.id"
                                  :id="feeTypes.id"
                                  :value="feeTypes"
                                  v-for="feeTypes in allFeeTypes.data"
                                >
                                  {{ feeTypes }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <transition name="slide-fade" appear>
                            <div class="col-6" v-if="fee.feeMonthly === true">
                              <div class="form__item form__item--label">
                                <label class="form__label"
                                  >Apply date method</label
                                >
                                <select
                                  class="select select--lg"
                                  required
                                  v-model="fee.feeApplyMethod"
                                >
                                  <option value="" disabled selected>
                                    Select fee payment
                                  </option>
                                  <option
                                    :key="appliedDates.id"
                                    v-for="appliedDates in allAppliedDates.data"
                                  >
                                    {{ appliedDates }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </transition>
                          <div class="col-6">
                            <div class="form__item form__item--label">
                              <label class="form__label">fee payment</label>
                              <select
                                class="select select--lg"
                                required
                                v-model="fee.feePayment"
                              >
                                <option value="" disabled selected>
                                  Select fee payment
                                </option>
                                <option value="flat">Flat</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-6">
                            <BaseInput
                              label="Amount"
                              type="text"
                              placeholder="Enter amount"
                              :value="inputCurrencyFormat(fee.feeAmount)"
                              @keyup="productFeeAmountChange($event, fee.id)"
                            />
                          </div>
                          <!-- <div class="col-6">
                            <BaseInput
                              label="Fee Income"
                              type="number"
                              min="0"
                              placeholder="Enter amount"
                              v-model.number="fee.feeIncome"
                            />
                          </div> -->
                        </div>
                        <button
                          type="button"
                          class="button ml-auto page__body__header__button button--cancel text--capital"
                          @click="deleteFee(counter)"
                        >
                          <span class="d-inline-flex">delete </span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div v-else>
                    <div v-for="(fee, counter) in form.fees" :key="counter">
                      <div class="form__item p-5 border border-radius">
                        <div class="row">
                          <div class="col-12">
                            <label
                              :for="counter"
                              class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                              >Activate
                              <input
                                type="checkbox"
                                :id="counter"
                                class="form-"
                                v-model="fee.activate"
                                :value="counter"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                          <div class="col-6">
                            <BaseInput
                              label="Name"
                              v-model="fee.feeName"
                              type="text"
                              placeholder="Enter name"
                            />
                          </div>
                          <div class="col-6">
                            <div class="form__item form__item--label">
                              <label class="form__label">Type</label>
                              <select
                                class="select select--lg"
                                required
                                v-model="fee.feeType"
                                @change="feeChange()"
                              >
                                <option value="" disabled selected>
                                  Select type
                                </option>
                                <option
                                  :key="feeTypes.id"
                                  :id="feeTypes.id"
                                  :value="feeTypes"
                                  v-for="feeTypes in allFeeTypes.data"
                                >
                                  {{ feeTypes }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <transition name="slide-fade" appear>
                            <div class="col-6" v-if="fee.feeMonthly === true">
                              <div class="form__item form__item--label">
                                <label class="form__label"
                                  >Apply date method</label
                                >
                                <select
                                  class="select select--lg"
                                  required
                                  v-model="fee.feeApplyMethod"
                                >
                                  <option value="" disabled selected>
                                    Select fee payment
                                  </option>
                                  <option
                                    :key="appliedDates.id"
                                    v-for="appliedDates in allAppliedDates.data"
                                  >
                                    {{ appliedDates }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </transition>
                          <div class="col-6">
                            <div class="form__item form__item--label">
                              <label class="form__label">fee payment</label>
                              <select
                                class="select select--lg"
                                required
                                v-model="fee.feePayment"
                              >
                                <option value="" disabled selected>
                                  Select fee payment
                                </option>
                                <option value="flat">Flat</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-6">
                            <BaseInput
                              label="Amount"
                              type="text"
                              placeholder="Enter amount"
                              :value="inputCurrencyFormat(fee.feeAmount)"
                              @keyup="productFeeAmountChange($event, fee.id)"
                            />
                          </div>
                          <div class="col-6">
                            <BaseInput
                              label="Fee Income"
                              type="number"
                              min="0"
                              placeholder="Enter amount"
                              v-model.number="fee.feeIncome"
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          class="button ml-auto page__body__header__button button--cancel text--capital"
                          @click="deleteFee(counter)"
                        >
                          <span class="d-inline-flex">delete </span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="form__item">
                    <button
                      type="button"
                      class="button page__body__header__button button--grey text--capital"
                      @click="addFee"
                    >
                      <ion-icon
                        name="add-outline"
                        class="text--regular"
                      ></ion-icon>
                      <span class="d-inline-flex ml-2">add fees</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ul>

          <div class="form__item">
            <div class="form__action">
              <button
                class="button form__button form__button--lg"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                class="button form__button form__button--lg save-changes"
                @click.prevent="onSave"
                v-else
              >
                Save Changes
              </button>
              <a
                role="button"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeCreateModal()"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
    </SideModal>

    <DeleteModal
      :show="showDeleteModal ? showDeleteModal : showDeleteProductModal"
      v-bind:close="closeDeleteModal"
      @close="closeDeleteModal"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Are you sure you want to
              {{ showDeleteModal ? "deactivate" : "delete" }} this product
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeDeleteModal"
              >
                cancel
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                @click="deactivateStatus()"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                @click="deactivateStatus()"
                v-else
              >
                {{ showDeleteModal ? "deactivate" : "delete" }}
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <EditModal
      v-bind:show="showEditModal"
      v-bind:close="closeEditModal"
      @close="closeEditModal"
    >
      <template v-slot:modal-head>
        <div class="modal__head__icon">
          <a class="modal__close modal__close--black" @click="cancel()">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </a>
        </div>
      </template>
      <template v-slot:modal-title>
        <h5 class="modal__head__title text--capital">Edit Deposit Product</h5>
      </template>
      <template v-slot:modal-body>
        <form>
          <div class="row">
            <div class="col-6">
              <BaseInput
                label="Deposit Product Name"
                v-model="form.productName"
                type="text"
                placeholder="Enter Product"
              />
              <div class="error" v-if="!$v.form.productName.required">
                <span class="text-danger"
                  >Deposit Product name is required</span
                >
              </div>
            </div>

            <div class="col-6">
              <BaseInput
                label="Deposit Product ID"
                v-model="form.productID"
                type="text"
                placeholder="Enter ID"
              />
              <div class="error" v-if="!$v.form.productID.required">
                <span class="text-danger">Deposit Product ID is required</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">Deposit Product Category</label>
                <select
                  class="select select--lg"
                  required
                  v-model="form.productCategory"
                >
                  <option value="" disabled selected>
                    Select Product Category
                  </option>
                  <option
                    v-bind:key="category.id"
                    v-for="category in allproductcategory.data"
                  >
                    {{ category }}
                  </option>
                </select>
                <div class="error" v-if="!$v.form.productCategory.required">
                  <span class="text-danger"
                    >Deposit Product category is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">Deposit Product Type</label>
                <select
                  class="select select--lg"
                  required
                  v-model="form.productType"
                  @change="checkProductType()"
                >
                  <option value="" disabled selected>
                    Select Product Type
                  </option>
                  <option
                    v-bind:key="product.id"
                    v-for="product in allProductTypes.data"
                  >
                    {{ product }}
                  </option>
                </select>
                <div class="error" v-if="!$v.form.productType.required">
                  <span class="text-danger"
                    >Deposit Product type is required</span
                  >
                </div>
              </div>
            </div>
          </div>

          <label class="form__label text--black text--capital mb-3"
            >state</label
          >
          <div>
            <label
              for="state"
              class="has-checkbox page__body__header__label text--capital mb-3 text--black"
              >active
              <input
                type="checkbox"
                id="state"
                class="form-"
                v-model="form.productState"
                :checked="form.productState"
              />
              <span class="checkmark"></span>
            </label>
          </div>

          <ul id="accordion">
            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#productDescription"
                aria-expanded="false"
                aria-controls="productDescription"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  product description
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div
                id="productDescription"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <div class="form__item">
                    <div id="content-container">
                      <TextArea
                        label="Description"
                        placeholder="Product Description"
                        v-model="form.productDescription"
                        cols="5"
                        rows="5"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#productAvailability"
                aria-expanded="false"
                aria-controls="productAvailability"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  product availability
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>

              <div
                id="productAvailability"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <label class="form__label text--grey text--capital mb-3"
                    >available to</label
                  >

                  <div class="d-flex flex-column">
                    <label
                      for="Customers"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >Customer
                      <input
                        type="checkbox"
                        v-model="form.availablity"
                        id="Customers"
                        value="Customers"
                        :checked="form.availablity"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label
                      for="Groups"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >Groups
                      <input
                        type="checkbox"
                        v-model="form.availablity"
                        id="Groups"
                        value="Groups"
                        :checked="form.availablity"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label
                      for="All_Branches"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >All Branches
                      <input
                        type="checkbox"
                        id="All_Branches"
                        value="All Branches"
                        v-model="form.forAllBranches"
                        :checked="form.forAllBranches"
                        @click="addBranches($event)"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form__item" v-if="branches === true">
                    <label
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >Branches</label
                    >
                    <multiselect
                      v-model="allBranches"
                      tag-placeholder="Add this as new tag"
                      placeholder="Search or add a Branch"
                      label="branchName"
                      track-by="branchName"
                      :options="options"
                      :trackBy="trackBy"
                      :multiple="true"
                      :taggable="true"
                      @tag="addTag"
                      @input="updateBranches"
                    ></multiselect>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#accountSettings"
                aria-expanded="false"
                aria-controls="accountSettings"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  new account settings
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div
                id="accountSettings"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">ID Type</label>
                        <select
                          class="select select--lg"
                          required
                          v-model="form.idType"
                          @change="newAccountSettings"
                        >
                          <option value="" disabled selected>
                            Select ID Type
                          </option>
                          <option
                            v-bind:key="IDType.id"
                            v-for="IDType in allIDTypes.data"
                          >
                            {{ IDType }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6" v-show="AccountSetting">
                      <BaseInput
                        label="Starting From"
                        v-model="form.idTemplate"
                        type="number"
                        min="0"
                        placeholder="Starting From"
                      />
                    </div>
                    <div class="col-6" v-show="template">
                      <BaseInput
                        label="Using Template"
                        :info="'The symbol # represent Numbers (Ex. 1,2,3...)\nThe symbol @ represent Alphabets (Ex. ABCabc)\nThe symbol $ represent AlphaNumeric (Ex. ABCabc123)'"
                        v-model="form.idTemplate"
                        type="text"
                        placeholder="Enter Template"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#currencies"
                aria-expanded="false"
                aria-controls="currencies"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  currencies
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="currencies" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="form__item">
                    <label class="form__label">currency</label>
                    <select
                      class="select select--lg"
                      required
                      v-model="form.currency"
                    >
                      <option value="" disabled selected>
                        Select any currency
                      </option>
                      <option
                        v-bind:key="currency.id"
                        v-for="currency in sortedCurrencies"
                        :id="currency.currencyCode"
                        :value="currency.currencyCode"
                        :selected="form.currency"
                      >
                        {{ currency.currencyCode }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#interestRate"
                aria-expanded="false"
                aria-controls="interestRate"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  interest rate
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="interestRate" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="form__item">
                    <label
                      for="interestpaid"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      v-if="depProdState === 'True'"
                      >Interest paid into account

                      <input
                        type="checkbox"
                        id="interestpaid"
                        class="form-"
                        v-model="form.interestPaidIntoAccount"
                        :checked="form.interestPaidIntoAccount"
                        disabled
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label
                      for="interestpaid"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      v-else
                      >Interest paid into account

                      <input
                        type="checkbox"
                        id="interestpaid"
                        class="form-"
                        v-model="form.interestPaidIntoAccount"
                        :checked="form.interestPaidIntoAccount"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div v-if="form.interestPaidIntoAccount === true">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Interest Rate Terms</label>
                          <select
                            class="select select--lg"
                            required
                            v-model="form.interestRateTerms"
                            v-if="depProdState === 'True'"
                            @change="interestHandler"
                            disabled
                          >
                            <option value="" disabled selected>
                              Select Interest Calculation
                            </option>
                            <option
                              v-bind:key="rate.id"
                              v-for="rate in allInterestRate.data"
                            >
                              {{ rate }}
                            </option>
                          </select>
                          <select
                            class="select select--lg"
                            required
                            v-model="form.interestRateTerms"
                            v-else
                            @change="interestHandler"
                          >
                            <option value="" disabled selected>
                              Select Interest Calculation
                            </option>
                            <option
                              v-bind:key="rate.id"
                              v-for="rate in allInterestRate.data"
                            >
                              {{ rate }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >How is the Interest rate charged?</label
                          >
                          <select
                            class="select select--lg"
                            required
                            v-model="form.interestChargeMethod"
                            @change="percentHandler()"
                          >
                            <option value="" disabled selected>
                              Select Accrued Interest
                            </option>
                            <option
                              v-bind:key="interest.id"
                              v-for="interest in allInterestCharge.data"
                            >
                              {{ interest }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6" v-show="days">
                        <div class="form__item">
                          <BaseInput
                            label="Percent Per x Days"
                            v-model.number="form.interestPerDays"
                            type="number"
                            min="0"
                            placeholder="Interest per x days"
                          />
                        </div>
                      </div>
                    </div>

                    <div v-show="fixedTerm">
                      <p
                        class="text--primary form__item_text text--regular mb-5"
                      >
                        Interest Rate Constraints (%)
                      </p>
                      <div class="row">
                        <div class="col-6">
                          <BaseInput
                            label="Defaults"
                            v-model.number="form.defaultInterestRateConstraints"
                            type="number"
                            min="0"
                            placeholder="Defaults"
                          />
                        </div>
                        <div class="col-6">
                          <BaseInput
                            label="Minimum"
                            v-model.number="form.minInterestRateConstraints"
                            type="number"
                            min="0"
                            placeholder="Enter minimum"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <BaseInput
                            label="Maximum"
                            v-model.number="form.maxInterestRateConstraints"
                            type="number"
                            min="0"
                            placeholder="Enter Maximum"
                          />
                        </div>
                      </div>
                    </div>

                    <div v-show="balancedTier">
                      <div
                        class="form__item p-5 border border-radius"
                        v-for="(balanceTier, counter) in form.balanceTiers"
                        :key="counter"
                      >
                        <p
                          class="text--primary form__item_text text--regular mb-5"
                        >
                          Tiered Per Balance {{ counter + 1 }}
                        </p>
                        <div class="row">
                          <div class="col-6">
                            <BaseInput
                              label="From"
                              v-model.number="balanceTier.fromTier"
                              type="number"
                              min="0.00"
                              placeholder="0.00"
                              value="0.0"
                              disabled
                            />
                          </div>
                          <div class="col-6">
                            <BaseInput
                              label="To"
                              :value="inputCurrencyFormat(balanceTier.toTier)"
                              type="text"
                              min="0.00"
                              placeholder="0.00"
                              @keyup="
                                productBalanceTierChange($event, balanceTier.id)
                              "
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">
                                {{ form.interestChargeMethod || "%" }}
                              </label>
                              <input
                                type="number"
                                placeholder="Enter Amount"
                                class="input form__input form__input--lg"
                                v-model.number="balanceTier.interestTierValue"
                                min="0"
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          class="button ml-auto page__body__header__button button--cancel text--capital"
                          @click="deleteBalanceTiers(counter)"
                        >
                          <span class="d-inline-flex">delete </span>
                        </button>
                      </div>
                      <div class="form__item">
                        <button
                          type="button"
                          class="button page__body__header__button button--grey text--capital"
                          @click="updateBalanceTiers"
                        >
                          <ion-icon
                            name="add-outline"
                            class="text--regular"
                          ></ion-icon>
                          <span class="d-inline-flex ml-2"
                            >add balance tiers</span
                          >
                        </button>
                      </div>
                    </div>

                    <div v-show="periodTiered">
                      <div
                        class="form__item p-5 border border-radius"
                        v-for="(periodTier, counter) in form.periodTiers"
                        :key="counter"
                      >
                        <p
                          class="text--primary form__item_text text--regular mb-5"
                        >
                          Tiered Per Period {{ counter + 1 }}
                        </p>
                        <div class="row">
                          <div class="col-6">
                            <BaseInput
                              label="From"
                              v-model.number="periodTier.fromTier"
                              type="number"
                              min="0"
                              placeholder="0"
                              disabled
                            />
                          </div>
                          <div class="col-6">
                            <BaseInput
                              label="To"
                              v-model.number="periodTier.toTier"
                              type="number"
                              min="0"
                              placeholder="0"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">
                                {{ form.interestChargeMethod }}
                                <span style="font-style: italic"
                                  >Calculated by number of days</span
                                >
                              </label>
                              <input
                                type="number"
                                placeholder="Enter Amount"
                                class="input form__input form__input--lg"
                                v-model.number="periodTier.interestTierValue"
                                min="0"
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          class="button ml-auto page__body__header__button button--cancel text--capital"
                          @click="deletePeriodTiers(counter)"
                        >
                          <span class="d-inline-flex">delete </span>
                        </button>
                      </div>
                      <div class="form__item">
                        <button
                          type="button"
                          class="button page__body__header__button button--grey text--capital"
                          @click="updatePeriodTiers"
                        >
                          <ion-icon
                            name="add-outline"
                            class="text--regular"
                          ></ion-icon>
                          <span class="d-inline-flex ml-2"
                            >add period tiers</span
                          >
                        </button>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >What Account Balance is Used for
                            Calculations?</label
                          >
                          <select
                            class="select select--lg"
                            required
                            v-model.trim="form.calculationAccountBalance"
                            @change="changeBalanceCalculation()"
                            v-if="depProdState === 'True'"
                            disabled
                          >
                            <option value="" disabled selected>
                              Select account
                            </option>
                            <option
                              v-bind:key="account_balance.id"
                              v-for="account_balance in allAccountBalanceCalculation.data"
                            >
                              {{ account_balance }}
                            </option>
                          </select>
                          <select
                            class="select select--lg"
                            required
                            v-model.trim="form.calculationAccountBalance"
                            @change="changeBalanceCalculation()"
                            v-else
                          >
                            <option value="" disabled selected>
                              Select account
                            </option>
                            <option
                              v-bind:key="account_balance.id"
                              v-for="account_balance in allAccountBalanceCalculation.data"
                            >
                              {{ account_balance }}
                            </option>
                          </select>
                        </div>
                        <div
                          class="error"
                          v-if="!$v.form.calculationAccountBalance.required"
                        >
                          <span class="text-danger"
                            >Calculation account balance is required</span
                          >
                        </div>
                      </div>
                      <div class="col-6" v-show="balanceCalc">
                        <div class="form__item">
                          <label class="form__label"
                            >Maximum Balance used for Interest
                            Calculation</label
                          >
                          <input
                            type="number"
                            min="0"
                            placeholder="Enter Days"
                            class="input form__input form__input--lg"
                            v-model.number="form.maxInterestCalculationBalance"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >When is the Interest Paid Into the Account?</label
                          >
                          <select
                            class="select select--lg"
                            required
                            v-model="form.interestPaymentPeriod"
                            @change="handleChange"
                          >
                            <option value="" disabled selected>
                              Select days
                            </option>
                            <option
                              :key="account_interests.id"
                              v-for="account_interests in allAccountInterests.data"
                            >
                              {{ account_interests }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Days In Year</label>
                          <select
                            class="select select--lg"
                            required
                            v-model="form.interestDaysInYears"
                            v-if="depProdState === 'True'"
                            disabled
                          >
                            <option value="" disabled selected>
                              Select Days In Year
                            </option>
                            <option
                              v-bind:key="days.id"
                              v-for="days in allDaysInYears.data"
                            >
                              {{ days }}
                            </option>
                          </select>
                          <select
                            class="select select--lg"
                            required
                            v-model="form.interestDaysInYears"
                            v-else
                          >
                            <option value="" disabled selected>
                              Select Days In Year
                            </option>
                            <option
                              v-bind:key="days.id"
                              v-for="days in allDaysInYears.data"
                            >
                              {{ days }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion" v-if="depProduct === true">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#depositandwithdrawals"
                aria-expanded="false"
                aria-controls="depositandwithdrawals"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  deposit & withdrawals
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div
                id="depositandwithdrawals"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <div class="row align-items-center">
                    <div class="col-6">
                      <div class="form__item">
                        <BaseInput
                          label="Recommended Deposit Amount"
                          v-model.number="form.defaultDepositAmount"
                          type="number"
                          min="0"
                          placeholder="Recommended Deposit Amount"
                        />
                      </div>
                    </div>
                    <div class="col-6" v-show="maxDepo">
                      <div class="form__item">
                        <BaseInput
                          label="Single Withdrawal Limit"
                          v-model.number="form.maxWithdrawalAmount"
                          type="number"
                          min="0"
                          placeholder="Single Withdrawal Limit"
                        />
                      </div>
                    </div>
                    <div class="col-6" v-show="maxDepo">
                      <div class="form__item">
                        <BaseInput
                          label="Daily Withdrawal Limit"
                          v-model.number="form.dailyWithdrawalLimit"
                          type="number"
                          min="0"
                          placeholder="Daily Withdrawal Limit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion" v-show="openBalance">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#openingbalance"
                aria-expanded="false"
                aria-controls="openingbalance"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Opening Balance
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div
                id="openingbalance"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <div class="row align-items-center">
                    <div class="col-6">
                      <div class="form__item">
                        <BaseInput
                          label="Default Opening Balance"
                          v-model.number="form.defaultOpeningBalance"
                          type="number"
                          min="0"
                          placeholder="Default Opening Balance"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <BaseInput
                          label="Minimum Opening Balance"
                          v-model.number="form.minOpeningBalance"
                          type="number"
                          min="0"
                          placeholder="Minimum Opening Balance"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <BaseInput
                          label="Maximum Opening Balance"
                          v-model.number="form.maxOpeningBalance"
                          type="number"
                          min="0"
                          placeholder="Maximum Opening Balance"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion" v-if="productType === true">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#termlength"
                aria-expanded="false"
                aria-controls="termlength"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Term Length
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="termlength" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="row align-items-center">
                    <div class="col-6">
                      <div class="form__item">
                        <BaseInput
                          label="Default Term Length"
                          v-model.number="form.defaultTermLength"
                          type="number"
                          min="0"
                          placeholder="Default Term Length"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <BaseInput
                          label="Minimum Term Length"
                          v-model.number="form.minTermLength"
                          type="number"
                          min="0"
                          placeholder="Minimum Term Length"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <BaseInput
                          label="Maximum Term Length"
                          v-model.number="form.maxTermLength"
                          type="number"
                          min="0"
                          placeholder="Maximum Term Length"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Term Unit</label>
                        <select
                          class="select select--lg"
                          required
                          v-model="form.termUnit"
                        >
                          <option value="" disabled selected>
                            Select Term Unit
                          </option>
                          <option
                            v-bind:key="alltermUnit.id"
                            v-for="alltermUnit in alltermUnits.data"
                            :id="alltermUnit.id"
                            :value="alltermUnit"
                            :selected="form.termUnit"
                          >
                            {{ alltermUnit }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#internalcontrol"
                aria-expanded="false"
                aria-controls="internalcontrol"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Internal Control
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div
                id="internalcontrol"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <div class="row align-items-center">
                    <div class="col-6">
                      <div class="form__item">
                        <label
                          for="autodormant"
                          class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                          >Automatically set account as dormant
                          <input
                            type="checkbox"
                            id="autodormant"
                            class="form-"
                            v-model="form.autoDormant"
                            :checked="form.autoDormant"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-6" v-if="form.autoDormant">
                      <div class="form__item">
                        <BaseInput
                          label="Automatically set account as dormant after X days"
                          v-model.number="form.autoDormantAfter"
                          type="number"
                          min="0"
                          placeholder="After"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#productFees"
                aria-expanded="false"
                aria-controls="productFees"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  product fees
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="productFees" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="form__item">
                    <label
                      for="allowArbitaryFees"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >allow Arbitary Fees
                      <input
                        type="checkbox"
                        id="allowArbitaryFees"
                        class="form-"
                        v-model="form.allowArbitaryFees"
                        :checked="form.allowArbitaryFees"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form__item">
                    <label
                      for="showInactiveFees"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >show Inactive Fees
                      <input
                        type="checkbox"
                        id="showInactiveFees"
                        class="form-"
                        v-model="form.showInactiveFees"
                        @click="showOnlyInactive('edit')"
                        :checked="form.showInactiveFees"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>

                  <div v-if="!form.showInactiveFees">
                    <div
                      v-for="(fee, counter) in showEditInactiveFeeData"
                      :key="counter"
                    >
                      <div class="form__item p-5 border border-radius">
                        <div class="row">
                          <div class="col-12">
                            <label
                              :for="counter"
                              class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                              >Activate
                              <input
                                type="checkbox"
                                :id="counter"
                                class="form-"
                                v-model="fee.activate"
                                :value="counter"
                                @click="updateActivate(fee, 'edit')"
                                :checked="fee.activate"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                          <div class="col-6">
                            <BaseInput
                              label="Name"
                              v-model="fee.feeName"
                              type="text"
                              placeholder="Enter name"
                            />
                          </div>
                          <div class="col-6">
                            <div class="form__item form__item--label">
                              <label class="form__label">Type</label>
                              <select
                                class="select select--lg"
                                required
                                v-model="fee.feeType"
                                @change="feeChange()"
                              >
                                <option value="" disabled selected>
                                  Select type
                                </option>
                                <option
                                  :key="feeTypes.id"
                                  :id="feeTypes.id"
                                  :value="feeTypes"
                                  v-for="feeTypes in allFeeTypes.data"
                                >
                                  {{ feeTypes }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-6"
                            v-if="fee.feeType === 'Monthly Fee'"
                          >
                            <div class="form__item form__item--label">
                              <label class="form__label"
                                >Apply date method</label
                              >
                              <select
                                class="select select--lg"
                                required
                                v-model="fee.feeApplyMethod"
                              >
                                <option value="" disabled selected>
                                  Select fee payment
                                </option>
                                <option
                                  :key="appliedDates.id"
                                  v-for="appliedDates in allAppliedDates.data"
                                >
                                  {{ appliedDates }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item form__item--label">
                              <label class="form__label">fee payment</label>
                              <select
                                class="select select--lg"
                                required
                                v-model="fee.feePayment"
                              >
                                <option value="" disabled selected>
                                  Select fee payment
                                </option>
                                <option
                                  :value="fee.feePayment"
                                  :selected="fee.feePayment"
                                >
                                  {{ fee.feePayment }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-6">
                            <BaseInput
                              label="Amount"
                              type="number"
                              min="0"
                              placeholder="Enter amount"
                              v-model="fee.feeAmount"
                              @keydown="
                                productFeeAmountChangeEdit(
                                  fee.feeAmount,
                                  fee.feeKey,
                                  'edit',
                                  fee.id
                                )
                              "
                            />
                          </div>
                          <!-- <div class="col-6">
                            <BaseInput
                              label="Fee Income"
                              type="number"
                              min="0"
                              placeholder="Enter amount"
                              v-model.number="fee.feeIncome"
                            />
                          </div> -->
                        </div>
                        <button
                          type="button"
                          class="button ml-auto page__body__header__button button--cancel text--capital"
                          @click="deleteFee(counter)"
                        >
                          <span class="d-inline-flex">delete </span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div v-else>
                    <div v-for="(fee, counter) in form.fees" :key="counter">
                      <div class="form__item p-5 border border-radius">
                        <div class="row">
                          <div class="col-12">
                            <label
                              :for="counter"
                              class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                              >Activate
                              <input
                                type="checkbox"
                                :id="counter"
                                class="form-"
                                v-model="fee.activate"
                                :value="counter"
                                :checked="fee.activate"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                          <div class="col-6">
                            <BaseInput
                              label="Name"
                              v-model="fee.feeName"
                              type="text"
                              placeholder="Enter name"
                            />
                          </div>
                          <div class="col-6">
                            <div class="form__item form__item--label">
                              <label class="form__label">Type</label>
                              <select
                                class="select select--lg"
                                required
                                v-model="fee.feeType"
                                @change="feeChange()"
                              >
                                <option value="" disabled selected>
                                  Select type
                                </option>
                                <option
                                  :key="feeTypes.id"
                                  :id="feeTypes.id"
                                  :value="feeTypes"
                                  v-for="feeTypes in allFeeTypes.data"
                                >
                                  {{ feeTypes }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-6"
                            v-if="fee.feeType === 'Monthly Fee'"
                          >
                            <div class="form__item form__item--label">
                              <label class="form__label"
                                >Apply date method</label
                              >
                              <select
                                class="select select--lg"
                                required
                                v-model="fee.feeApplyMethod"
                              >
                                <option value="" disabled selected>
                                  Select fee payment
                                </option>
                                <option
                                  :key="appliedDates.id"
                                  v-for="appliedDates in allAppliedDates.data"
                                >
                                  {{ appliedDates }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item form__item--label">
                              <label class="form__label">fee payment</label>
                              <select
                                class="select select--lg"
                                required
                                v-model="fee.feePayment"
                              >
                                <option value="" disabled selected>
                                  Select fee payment
                                </option>
                                <option
                                  :value="fee.feePayment"
                                  :selected="fee.feePayment"
                                >
                                  {{ fee.feePayment }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-6">
                            <BaseInput
                              label="Amount"
                              type="number"
                              min="0"
                              placeholder="Enter amount"
                              v-model="fee.feeAmount"
                              @input="
                                productFeeAmountChangeEdit(
                                  fee.feeAmount,
                                  fee.feeKey,
                                  'edit',
                                  fee.id
                                )
                              "
                            />
                          </div>
                          <div class="col-6">
                            <BaseInput
                              label="Fee Income"
                              type="number"
                              min="0"
                              placeholder="Enter amount"
                              v-model.number="fee.feeIncome"
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          class="button ml-auto page__body__header__button button--cancel text--capital"
                          @click="deleteFee(counter)"
                        >
                          <span class="d-inline-flex">delete </span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="form__item">
                    <button
                      type="button"
                      class="button page__body__header__button button--grey text--capital"
                      @click="addFee"
                    >
                      <ion-icon
                        name="add-outline"
                        class="text--regular"
                      ></ion-icon>
                      <span class="d-inline-flex ml-2">add fees</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ul>

          <div class="form__item">
            <div class="form__action">
              <button
                class="button form__button form__button--lg"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                class="button form__button form__button--lg"
                @click.prevent="update"
                v-else
              >
                Update changes
              </button>
              <a
                role="button"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="cancel()"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
    </EditModal>

    <!-- Copy Product -->
    <EditModal
      v-bind:show="showCopyProductModal"
      v-bind:close="closeCopyProductModal"
      @close="closeCopyProductModal"
    >
      <template v-slot:modal-head>
        <div class="modal__head__icon">
          <a
            class="modal__close modal__close--black"
            @click="closeCopyProductModal()"
          >
            <ion-icon name="arrow-back-outline"></ion-icon>
          </a>
        </div>
      </template>
      <template v-slot:modal-title>
        <h5 class="modal__head__title text--capital">Copy Deposit Product</h5>
      </template>
      <template v-slot:modal-body>
        <form>
          <div class="row">
            <div class="col-6">
              <BaseInput
                label="Deposit Product Name"
                v-model="form.productName"
                type="text"
                placeholder="Enter Product"
              />
              <div class="error" v-if="!$v.form.productName.required">
                <span class="text-danger"
                  >Deposit Product name is required</span
                >
              </div>
            </div>

            <div class="col-6">
              <BaseInput
                label="Deposit Product ID"
                v-model="form.productID"
                type="text"
                placeholder="Enter ID"
              />
              <div class="error" v-if="!$v.form.productID.required">
                <span class="text-danger">Deposit Product ID is required</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">Deposit Product Category</label>
                <select
                  class="select select--lg"
                  required
                  v-model="form.productCategory"
                >
                  <option value="" disabled selected>
                    Select Product Category
                  </option>
                  <option
                    v-bind:key="category.id"
                    v-for="category in allproductcategory.data"
                  >
                    {{ category }}
                  </option>
                </select>
                <div class="error" v-if="!$v.form.productCategory.required">
                  <span class="text-danger"
                    >Deposit Product category is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">Deposit Product Type</label>
                <select
                  class="select select--lg"
                  required
                  v-model="form.productType"
                  @change="checkProductType()"
                >
                  <option value="" disabled selected>
                    Select Product Type
                  </option>
                  <option
                    v-bind:key="product.id"
                    v-for="product in allProductTypes.data"
                  >
                    {{ product }}
                  </option>
                </select>
                <div class="error" v-if="!$v.form.productType.required">
                  <span class="text-danger"
                    >Deposit Product type is required</span
                  >
                </div>
              </div>
            </div>
          </div>

          <label class="form__label text--black text--capital mb-3"
            >state</label
          >
          <div>
            <label
              for="state"
              class="has-checkbox page__body__header__label text--capital mb-3 text--black"
              >active
              <input
                type="checkbox"
                id="state"
                class="form-"
                v-model="form.productState"
                :checked="form.productState"
              />
              <span class="checkmark"></span>
            </label>
          </div>

          <ul id="accordion">
            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#productDescription"
                aria-expanded="false"
                aria-controls="productDescription"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  product description
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div
                id="productDescription"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <div class="form__item">
                    <div id="content-container">
                      <TextArea
                        label="Description"
                        placeholder="Product Description"
                        v-model="form.productDescription"
                        cols="5"
                        rows="5"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#productAvailability"
                aria-expanded="false"
                aria-controls="productAvailability"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  product availability
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>

              <div
                id="productAvailability"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <label class="form__label text--grey text--capital mb-3"
                    >available to</label
                  >

                  <div class="d-flex flex-column">
                    <label
                      for="Customers"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >Customer
                      <input
                        type="checkbox"
                        v-model="form.availablity"
                        id="Customers"
                        value="Customers"
                        :checked="form.availablity"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label
                      for="Groups"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >Groups
                      <input
                        type="checkbox"
                        v-model="form.availablity"
                        id="Groups"
                        value="Groups"
                        :checked="form.availablity"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label
                      for="All_Branches"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >All Branches
                      <input
                        type="checkbox"
                        id="All_Branches"
                        value="All Branches"
                        v-model="form.forAllBranches"
                        :checked="form.forAllBranches"
                        @click="addBranches($event)"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form__item" v-if="branches === true">
                    <label
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >Branches</label
                    >
                    <multiselect
                      v-model="allBranches"
                      tag-placeholder="Add this as new tag"
                      placeholder="Search or add a Branch"
                      label="branchName"
                      track-by="branchName"
                      :options="options"
                      :trackBy="trackBy"
                      :multiple="true"
                      :taggable="true"
                      @tag="addTag"
                      @input="updateBranches"
                    ></multiselect>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#accountSettings"
                aria-expanded="false"
                aria-controls="accountSettings"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  new account settings
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div
                id="accountSettings"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">ID Type</label>
                        <select
                          class="select select--lg"
                          required
                          v-model="form.idType"
                          @change="newAccountSettings"
                        >
                          <option value="" disabled selected>
                            Select ID Type
                          </option>
                          <option
                            v-bind:key="IDType.id"
                            v-for="IDType in allIDTypes.data"
                          >
                            {{ IDType }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6" v-show="AccountSetting">
                      <BaseInput
                        label="Starting From"
                        v-model="form.idTemplate"
                        type="number"
                        min="0"
                        placeholder="Starting From"
                      />
                    </div>
                    <div class="col-6" v-show="template">
                      <BaseInput
                        label="Using Template"
                        v-model="form.idTemplate"
                        type="text"
                        placeholder="Enter Template"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#currencies"
                aria-expanded="false"
                aria-controls="currencies"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  currencies
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="currencies" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="form__item">
                    <label class="form__label">currency</label>
                    <select
                      class="select select--lg"
                      required
                      v-model="form.currency"
                    >
                      <option value="" disabled selected>
                        Select any currency
                      </option>
                      <option
                        v-bind:key="currency.id"
                        v-for="currency in sortedCurrencies"
                        :id="currency.currencyCode"
                        :value="currency.currencyCode"
                        :selected="form.currency"
                      >
                        {{ currency.currencyCode }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#interestRate"
                aria-expanded="false"
                aria-controls="interestRate"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  interest rate
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="interestRate" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="form__item">
                    <label
                      for="interestpaid"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      v-if="depProdState === 'True'"
                      >Interest paid into account

                      <input
                        type="checkbox"
                        id="interestpaid"
                        class="form-"
                        v-model="form.interestPaidIntoAccount"
                        :checked="form.interestPaidIntoAccount"
                        disabled
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label
                      for="interestpaid"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      v-else
                      >Interest paid into account

                      <input
                        type="checkbox"
                        id="interestpaid"
                        class="form-"
                        v-model="form.interestPaidIntoAccount"
                        :checked="form.interestPaidIntoAccount"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div v-if="form.interestPaidIntoAccount === true">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Interest Rate Terms</label>
                          <select
                            class="select select--lg"
                            required
                            v-model="form.interestRateTerms"
                            v-if="depProdState === 'True'"
                            @change="interestHandler"
                            disabled
                          >
                            <option value="" disabled selected>
                              Select Interest Calculation
                            </option>
                            <option
                              v-bind:key="rate.id"
                              v-for="rate in allInterestRate.data"
                            >
                              {{ rate }}
                            </option>
                          </select>
                          <select
                            class="select select--lg"
                            required
                            v-model="form.interestRateTerms"
                            v-else
                            @change="interestHandler"
                          >
                            <option value="" disabled selected>
                              Select Interest Calculation
                            </option>
                            <option
                              v-bind:key="rate.id"
                              v-for="rate in allInterestRate.data"
                            >
                              {{ rate }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >How is the Interest rate charged?</label
                          >
                          <select
                            class="select select--lg"
                            required
                            v-model="form.interestChargeMethod"
                            @change="percentHandler()"
                          >
                            <option value="" disabled selected>
                              Select Accrued Interest
                            </option>
                            <option
                              v-bind:key="interest.id"
                              v-for="interest in allInterestCharge.data"
                            >
                              {{ interest }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6" v-show="days">
                        <div class="form__item">
                          <BaseInput
                            label="Percent Per x Days"
                            v-model.number="form.interestPerDays"
                            type="number"
                            min="0"
                            placeholder="Interest per x days"
                          />
                        </div>
                      </div>
                    </div>

                    <div v-show="fixedTerm">
                      <p
                        class="text--primary form__item_text text--regular mb-5"
                      >
                        Interest Rate Constraints (%)
                      </p>
                      <div class="row">
                        <div class="col-6">
                          <BaseInput
                            label="Defaults"
                            v-model.number="form.defaultInterestRateConstraints"
                            type="number"
                            min="0"
                            placeholder="Defaults"
                          />
                        </div>
                        <div class="col-6">
                          <BaseInput
                            label="Minimum"
                            v-model.number="form.minInterestRateConstraints"
                            type="number"
                            min="0"
                            placeholder="Enter minimum"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <BaseInput
                            label="Maximum"
                            v-model.number="form.maxInterestRateConstraints"
                            type="number"
                            min="0"
                            placeholder="Enter Maximum"
                          />
                        </div>
                      </div>
                    </div>

                    <div v-show="balancedTier">
                      <div class="form__item">
                        <button
                          type="button"
                          class="button page__body__header__button button--grey text--capital"
                          @click="updateBalanceTiers"
                        >
                          <ion-icon
                            name="add-outline"
                            class="text--regular"
                          ></ion-icon>
                          <span class="d-inline-flex ml-2"
                            >add balance tiers</span
                          >
                        </button>
                      </div>
                      <div
                        class="form__item p-5 border border-radius"
                        v-for="(balanceTier, counter) in form.balanceTiers"
                        :key="counter"
                      >
                        <p
                          class="text--primary form__item_text text--regular mb-5"
                        >
                          Tiered Per Balance {{ counter + 1 }}
                        </p>
                        <div class="row">
                          <div class="col-6">
                            <BaseInput
                              label="From"
                              v-model.number="balanceTier.fromTier"
                              type="number"
                              min="0.00"
                              placeholder="0.00"
                              value="0.0"
                              disabled
                            />
                          </div>
                          <div class="col-6">
                            <BaseInput
                              label="To"
                              :value="inputCurrencyFormat(balanceTier.toTier)"
                              type="text"
                              min="0.00"
                              placeholder="0.00"
                              @keyup="
                                productBalanceTierChange($event, balanceTier.id)
                              "
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">
                                {{ form.interestChargeMethod || "%" }}
                              </label>
                              <input
                                type="number"
                                placeholder="Enter Amount"
                                class="input form__input form__input--lg"
                                v-model.number="balanceTier.interestTierValue"
                                min="0"
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          class="button ml-auto page__body__header__button button--cancel text--capital"
                          @click="deleteBalanceTiers(counter)"
                        >
                          <span class="d-inline-flex">delete </span>
                        </button>
                      </div>
                    </div>

                    <div v-show="periodTiered">
                      <div class="form__item">
                        <button
                          type="button"
                          class="button page__body__header__button button--grey text--capital"
                          @click="updatePeriodTiers"
                        >
                          <ion-icon
                            name="add-outline"
                            class="text--regular"
                          ></ion-icon>
                          <span class="d-inline-flex ml-2"
                            >add period tiers</span
                          >
                        </button>
                      </div>
                      <div
                        class="form__item p-5 border border-radius"
                        v-for="(periodTier, counter) in form.periodTiers"
                        :key="counter"
                      >
                        <p
                          class="text--primary form__item_text text--regular mb-5"
                        >
                          Tiered Per Period {{ counter + 1 }}
                        </p>
                        <div class="row">
                          <div class="col-6">
                            <BaseInput
                              label="From"
                              v-model.number="periodTier.fromTier"
                              type="number"
                              min="0"
                              placeholder="0"
                              disabled
                            />
                          </div>
                          <div class="col-6">
                            <BaseInput
                              label="To"
                              v-model.number="periodTier.toTier"
                              type="number"
                              min="0"
                              placeholder="0"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">
                                {{ form.interestChargeMethod }}
                                <span style="font-style: italic"
                                  >Calculated by number of days</span
                                >
                              </label>
                              <input
                                type="number"
                                placeholder="Enter Amount"
                                class="input form__input form__input--lg"
                                v-model.number="periodTier.interestTierValue"
                                min="0"
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          class="button ml-auto page__body__header__button button--cancel text--capital"
                          @click="deletePeriodTiers(counter)"
                        >
                          <span class="d-inline-flex">delete </span>
                        </button>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >What Account Balance is Used for
                            Calculations?</label
                          >
                          <select
                            class="select select--lg"
                            required
                            v-model.trim="form.calculationAccountBalance"
                            @change="changeBalanceCalculation()"
                            v-if="depProdState === 'True'"
                            disabled
                          >
                            <option value="" disabled selected>
                              Select account
                            </option>
                            <option
                              v-bind:key="account_balance.id"
                              v-for="account_balance in allAccountBalanceCalculation.data"
                            >
                              {{ account_balance }}
                            </option>
                          </select>
                          <select
                            class="select select--lg"
                            required
                            v-model.trim="form.calculationAccountBalance"
                            @change="changeBalanceCalculation()"
                            v-else
                          >
                            <option value="" disabled selected>
                              Select account
                            </option>
                            <option
                              v-bind:key="account_balance.id"
                              v-for="account_balance in allAccountBalanceCalculation.data"
                            >
                              {{ account_balance }}
                            </option>
                          </select>
                        </div>
                        <div
                          class="error"
                          v-if="!$v.form.calculationAccountBalance.required"
                        >
                          <span class="text-danger"
                            >Calculation account balance is required</span
                          >
                        </div>
                      </div>
                      <div class="col-6" v-show="balanceCalc">
                        <div class="form__item">
                          <label class="form__label"
                            >Maximum Balance used for Interest
                            Calculation</label
                          >
                          <input
                            type="number"
                            min="0"
                            placeholder="Enter Days"
                            class="input form__input form__input--lg"
                            v-model.number="form.maxInterestCalculationBalance"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >When is the Interest Paid Into the Account?</label
                          >
                          <select
                            class="select select--lg"
                            required
                            v-model="form.interestPaymentPeriod"
                            @change="handleChange"
                          >
                            <option value="" disabled selected>
                              Select days
                            </option>
                            <option
                              :key="account_interests.id"
                              v-for="account_interests in allAccountInterests.data"
                            >
                              {{ account_interests }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Days In Year</label>
                          <select
                            class="select select--lg"
                            required
                            v-model="form.interestDaysInYears"
                            v-if="depProdState === 'True'"
                            disabled
                          >
                            <option value="" disabled selected>
                              Select Days In Year
                            </option>
                            <option
                              v-bind:key="days.id"
                              v-for="days in allDaysInYears.data"
                            >
                              {{ days }}
                            </option>
                          </select>
                          <select
                            class="select select--lg"
                            required
                            v-model="form.interestDaysInYears"
                            v-else
                          >
                            <option value="" disabled selected>
                              Select Days In Year
                            </option>
                            <option
                              v-bind:key="days.id"
                              v-for="days in allDaysInYears.data"
                            >
                              {{ days }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion" v-if="depProduct === true">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#depositandwithdrawals"
                aria-expanded="false"
                aria-controls="depositandwithdrawals"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  deposit & withdrawals
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div
                id="depositandwithdrawals"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <div class="row align-items-center">
                    <div class="col-6">
                      <div class="form__item">
                        <BaseInput
                          label="Recommended Deposit Amount"
                          v-model.number="form.defaultDepositAmount"
                          type="number"
                          min="0"
                          placeholder="Recommended Deposit Amount"
                        />
                      </div>
                    </div>
                    <div class="col-6" v-show="maxDepo">
                      <div class="form__item">
                        <BaseInput
                          label="Single Withdrawal Limit"
                          v-model.number="form.maxWithdrawalAmount"
                          type="number"
                          min="0"
                          placeholder="Single Withdrawal Limit"
                        />
                      </div>
                    </div>
                    <div class="col-6" v-show="maxDepo">
                      <div class="form__item">
                        <BaseInput
                          label="Daily Withdrawal Limit"
                          v-model.number="form.dailyWithdrawalLimit"
                          type="number"
                          min="0"
                          placeholder="Daily Withdrawal Limit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion" v-show="openBalance">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#openingbalance"
                aria-expanded="false"
                aria-controls="openingbalance"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Opening Balance
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div
                id="openingbalance"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <div class="row align-items-center">
                    <div class="col-6">
                      <div class="form__item">
                        <BaseInput
                          label="Default Opening Balance"
                          v-model.number="form.defaultOpeningBalance"
                          type="number"
                          min="0"
                          placeholder="Default Opening Balance"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <BaseInput
                          label="Minimum Opening Balance"
                          v-model.number="form.minOpeningBalance"
                          type="number"
                          min="0"
                          placeholder="Minimum Opening Balance"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <BaseInput
                          label="Maximum Opening Balance"
                          v-model.number="form.maxOpeningBalance"
                          type="number"
                          min="0"
                          placeholder="Maximum Opening Balance"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion" v-if="productType === true">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#termlength"
                aria-expanded="false"
                aria-controls="termlength"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Term Length
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="termlength" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="row align-items-center">
                    <div class="col-6">
                      <div class="form__item">
                        <BaseInput
                          label="Default Term Length"
                          v-model.number="form.defaultTermLength"
                          type="number"
                          min="0"
                          placeholder="Default Term Length"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <BaseInput
                          label="Minimum Term Length"
                          v-model.number="form.minTermLength"
                          type="number"
                          min="0"
                          placeholder="Minimum Term Length"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <BaseInput
                          label="Maximum Term Length"
                          v-model.number="form.maxTermLength"
                          type="number"
                          min="0"
                          placeholder="Maximum Term Length"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Term Unit</label>
                        <select
                          class="select select--lg"
                          required
                          v-model="form.termUnit"
                        >
                          <option value="" disabled selected>
                            Select Term Unit
                          </option>
                          <option
                            v-bind:key="alltermUnit.id"
                            v-for="alltermUnit in alltermUnits.data"
                            :id="alltermUnit.id"
                            :value="alltermUnit"
                            :selected="form.termUnit"
                          >
                            {{ alltermUnit }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#internalcontrol"
                aria-expanded="false"
                aria-controls="internalcontrol"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Internal Control
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div
                id="internalcontrol"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <div class="row align-items-center">
                    <div class="col-6">
                      <div class="form__item">
                        <label
                          for="autodormant"
                          class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                          >Automatically set account as dormant
                          <input
                            type="checkbox"
                            id="autodormant"
                            class="form-"
                            v-model="form.autoDormant"
                            :checked="form.autoDormant"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-6" v-if="form.autoDormant">
                      <div class="form__item">
                        <BaseInput
                          label="Automatically set account as dormant after X days"
                          v-model.number="form.autoDormantAfter"
                          type="number"
                          min="0"
                          placeholder="After"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#productFees"
                aria-expanded="false"
                aria-controls="productFees"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  product fees
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="productFees" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="form__item">
                    <label
                      for="allowArbitaryFees"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >allow Arbitrary Fees
                      <input
                        type="checkbox"
                        id="allowArbitaryFees"
                        class="form-"
                        v-model="form.allowArbitaryFees"
                        :checked="form.allowArbitaryFees"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form__item">
                    <label
                      for="showInactiveFees"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >show Inactive Fees
                      <input
                        type="checkbox"
                        id="showInactiveFees"
                        class="form-"
                        v-model="form.showInactiveFees"
                        :checked="form.showInactiveFees"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form__item">
                    <button
                      type="button"
                      class="button page__body__header__button button--grey text--capital"
                      @click="addFee"
                    >
                      <ion-icon
                        name="add-outline"
                        class="text--regular"
                      ></ion-icon>
                      <span class="d-inline-flex ml-2">add fees</span>
                    </button>
                  </div>

                  <div v-for="(fee, counter) in form.fees" :key="counter">
                    <div class="form__item p-5 border border-radius">
                      <div class="row">
                        <div class="col-12">
                          <label
                            :for="counter"
                            class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                            >Activate
                            <input
                              type="checkbox"
                              :id="counter"
                              class="form-"
                              v-model="fee.activate"
                              :value="counter"
                              :checked="fee.activate"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="col-6">
                          <BaseInput
                            label="Name"
                            v-model="fee.feeName"
                            type="text"
                            placeholder="Enter name"
                          />
                        </div>
                        <div class="col-6">
                          <div class="form__item form__item--label">
                            <label class="form__label">Type</label>
                            <select
                              class="select select--lg"
                              required
                              v-model="fee.feeType"
                              @change="feeChange()"
                            >
                              <option value="" disabled selected>
                                Select type
                              </option>
                              <option
                                :key="feeTypes.id"
                                :id="feeTypes.id"
                                :value="feeTypes"
                                v-for="feeTypes in allFeeTypes.data"
                              >
                                {{ feeTypes }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6" v-if="fee.feeType === 'Monthly Fee'">
                          <div class="form__item form__item--label">
                            <label class="form__label">Apply date method</label>
                            <select
                              class="select select--lg"
                              required
                              v-model="fee.feeApplyMethod"
                            >
                              <option value="" disabled selected>
                                Select fee payment
                              </option>
                              <option
                                :key="appliedDates.id"
                                v-for="appliedDates in allAppliedDates.data"
                              >
                                {{ appliedDates }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item form__item--label">
                            <label class="form__label">fee payment</label>
                            <select
                              class="select select--lg"
                              required
                              v-model="fee.feePayment"
                            >
                              <option value="" disabled selected>
                                Select fee payment
                              </option>
                              <option
                                :value="fee.feePayment"
                                :selected="fee.feePayment"
                              >
                                {{ fee.feePayment }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-6">
                          <BaseInput
                            label="Amount"
                            type="number"
                            min="0"
                            placeholder="Enter amount"
                            :value="inputCurrencyFormat(fee.feeAmount)"
                            @keyup="productFeeAmountChange($event, fee.id)"
                          />
                        </div>
                        <!-- <div class="col-6">
                          <BaseInput
                            label="Fee Income"
                            type="number"
                            min="0"
                            placeholder="Enter amount"
                            v-model.number="fee.feeIncome"
                          />
                        </div> -->
                      </div>
                      <button
                        type="button"
                        class="button ml-auto page__body__header__button button--cancel text--capital"
                        @click="deleteFee(counter)"
                      >
                        <span class="d-inline-flex">delete </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ul>

          <div class="form__item">
            <div class="form__action">
              <button
                class="button form__button form__button--lg"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                class="button form__button form__button--lg"
                @click.prevent="onSave"
                v-else
              >
                Save changes
              </button>
              <a
                role="button"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeCopyProductModal()"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
    </EditModal>

    <!-- Activate product -->
    <DeleteModal
      v-bind:show="showConfirmModal"
      v-bind:close="closeConfirmModal"
      @close="closeConfirmModal"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Are you sure you want to activate this product
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeConfirmModal"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                @click="activateStatus()"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                @click="activateStatus()"
                v-else
              >
                Activate
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <!-- Save Product -->
    <DeleteModal
      v-bind:show="showConfirmSave"
      v-bind:close="closeConfirmSave"
      @close="closeConfirmSave"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Are you sure you want to Save this product
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeConfirmSave"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                @click="onSubmit()"
                v-else
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <DeleteModal
      v-bind:show="showConfirmUpdate"
      v-bind:close="closeConfirmUpdate"
      @close="closeConfirmUpdate"
    >
      <template v-slot:modal-body>
        <div class="modal__content small__p">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Are you sure you want to Save this product?
            </h5>
            <div
              v-if="depProd.interestChargeMethod !== form.interestChargeMethod"
            >
              <p
                class="form__label"
                v-if="
                  depProd.interestChargeMethod === form.interestChargeMethod
                "
              ></p>
              <p
                class="form__label"
                v-else-if="
                  depProd.interestChargeMethod !== null &&
                  form.interestChargeMethod !== depProd.interestChargeMethod
                "
              >
                Changed Interest Charge Method from
                {{ depProd.interestChargeMethod }} to
                {{ form.interestChargeMethod }}
              </p>
              <p
                class="form__label"
                v-else-if="
                  depProd.interestChargeMethod === null &&
                  form.interestChargeMethod !== ''
                "
              >
                Added Interest Charge Method '{{ form.interestChargeMethod }}'
              </p>
              <p
                class="form__label"
                v-else-if="depProd.interestChargeMethod === null"
              >
                Added Interest Charge Method '{{ form.interestChargeMethod }}'
              </p>
            </div>
            <div
              v-if="
                depProd.interestPaymentPeriod !== form.interestPaymentPeriod
              "
            >
              <p
                class="form__label"
                v-if="
                  depProd.interestPaymentPeriod === form.interestPaymentPeriod
                "
              ></p>
              <p
                class="form__label"
                v-else-if="
                  depProd.interestPaymentPeriod !== null &&
                  form.interestPaymentPeriod !== depProd.interestPaymentPeriod
                "
              >
                Changed Interest Payment Period from
                {{ depProd.interestPaymentPeriod }} to
                {{ form.interestPaymentPeriod }}
              </p>
              <p
                class="form__label"
                v-else-if="
                  depProd.interestPaymentPeriod === null &&
                  form.interestPaymentPeriod !== ''
                "
              >
                Added Interest Payment Period '{{ form.interestPaymentPeriod }}'
              </p>
              <p
                class="form__label"
                v-else-if="depProd.interestPaymentPeriod === null"
              >
                Added Interest Payment Period '{{ form.interestPaymentPeriod }}'
              </p>
            </div>
            <div v-if="form.interestRateTerms === 'Fixed'">
              <div
                v-if="
                  depProd.defaultInterestRateConstraints !==
                  form.defaultInterestRateConstraints
                "
              >
                <p
                  class="form__label"
                  v-if="
                    depProd.defaultInterestRateConstraints ===
                    form.defaultInterestRateConstraints
                  "
                ></p>
                <p
                  class="form__label"
                  v-else-if="
                    depProd.defaultInterestRateConstraints !== null &&
                    form.defaultInterestRateConstraints !==
                      depProd.defaultInterestRateConstraints
                  "
                >
                  Changed Default Interest Rate (Interest) from
                  {{ depProd.defaultInterestRateConstraints }}% to
                  {{ form.defaultInterestRateConstraints }}%
                </p>
                <p
                  class="form__label"
                  v-else-if="depProd.defaultInterestRateConstraints === null"
                >
                  Added Default Interest Rate (Interest) '{{
                    form.defaultInterestRateConstraints
                  }}%'
                </p>
              </div>
              <div
                v-if="
                  depProd.minInterestRateConstraints !==
                  form.minInterestRateConstraints
                "
              >
                <p
                  class="form__label"
                  v-if="
                    depProd.minInterestRateConstraints ===
                    form.minInterestRateConstraints
                  "
                ></p>
                <p
                  class="form__label"
                  v-else-if="
                    depProd.minInterestRateConstraints !== null &&
                    form.minInterestRateConstraints !==
                      depProd.minInterestRateConstraints
                  "
                >
                  Changed Min Interest Rate (Interest) from
                  {{ depProd.minInterestRateConstraints }}% to
                  {{ form.minInterestRateConstraints }}%
                </p>
                <p
                  class="form__label"
                  v-else-if="depProd.minInterestRateConstraints === null"
                >
                  Added Min Interest Rate (Interest) '{{
                    form.minInterestRateConstraints
                  }}%'
                </p>
              </div>
              <div
                v-if="
                  depProd.maxInterestRateConstraints !==
                  form.maxInterestRateConstraints
                "
              >
                <p
                  class="form__label"
                  v-if="
                    depProd.maxInterestRateConstraints ===
                    form.maxInterestRateConstraints
                  "
                ></p>
                <p
                  class="form__label"
                  v-else-if="
                    depProd.maxInterestRateConstraints !== null &&
                    form.maxInterestRateConstraints !==
                      depProd.maxInterestRateConstraints
                  "
                >
                  Changed Max Interest Rate (Interest) from
                  {{ depProd.maxInterestRateConstraints }}% to
                  {{ form.maxInterestRateConstraints }}%
                </p>
                <p
                  class="form__label"
                  v-else-if="depProd.maxInterestRateConstraints === null"
                >
                  Added Max Interest Rate (Interest) '{{
                    form.maxInterestRateConstraints
                  }}%'
                </p>
              </div>
            </div>

            <div
              class="form__item form__item--label mt-5"
              v-if="
                depProd.interestChargeMethod !== form.interestChargeMethod ||
                depProd.interestPaymentPeriod !== form.interestPaymentPeriod ||
                depProd.interestDaysInYears !== form.interestDaysInYears ||
                depProd.calculationAccountBalance !==
                  form.calculationAccountBalance
              "
            >
              <label class="form__label"
                >Which accounts should the new interest settings apply</label
              >
              <select class="select select--lg" required>
                <option value="" disabled selected></option>
                <option value="All Existing and New Accounts">
                  All Existing and New Accounts
                </option>
                <option value="New Accounts Only">New Accounts Only</option>
              </select>
            </div>

            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @close="closeConfirmUpdate"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                @click="onUpdate()"
                v-else
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <DeleteModal
      :show="!userCanViewDepositProduct"
      :title="'Unauthorized'"
      @close="goToDashboard"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">Unauthorized</h5>
            <!-- <h5 class="text-danger">
              You don
            </h5> -->
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                @click.prevent="goToDashboard"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <!-- Disbursement modal -->
    <createAccount
      v-bind:show="showCreateAccountModal"
      v-bind:close="closeCreateAccountModal"
      @close="closeCreateAccountModal"
    >
      <template v-slot:modal-head>
        <div class="modal__head__icon">
          <a
            class="modal__close modal__close--black"
            @click="closeCreateAccountModal()"
          >
            <ion-icon name="arrow-back-outline"></ion-icon>
          </a>
        </div>
      </template>
      <template v-slot:modal-body>
        <form>
          <div v-show="depositForm">
            <div class="col-12 no--padding">
              <div class="form__item">
                <label class="form__label">Select Customer or Group</label>
                <select
                  class="select select--lg"
                  required
                  v-model.trim="account.accountType"
                >
                  <option value="" selected default>Select account type</option>
                  <option
                    v-bind:key="available.id"
                    v-for="available in depProd.availability"
                    :id="available.id"
                    :value="available"
                  >
                    {{ available }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 no--padding">
              <div class="form__item">
                <label class="form__label" for="ajax"
                  >Search Customer/Group</label
                >
                <div v-if="account.accountType === 'Customers'">
                  <multiselect
                    v-model="selectedCustomer"
                    id="ajax"
                    label="customerName"
                    track-by="customerName"
                    placeholder="Type to search"
                    open-direction="bottom"
                    :options="customers"
                    :multiple="false"
                    :searchable="true"
                    :internal-search="false"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :options-limit="300"
                    :limit="3"
                    :limit-text="limitText"
                    :max-height="800"
                    :show-no-results="false"
                    :hide-selected="true"
                    @search-change="apifetch"
                    required
                  >
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="custom__tag">
                        <span>{{ option.customerName }}</span>
                        <span class="custom__remove" @click="remove(option)"
                          >❌</span
                        >
                      </span>
                    </template>
                  </multiselect>
                </div>
                <div v-else>
                  <multiselect
                    v-model="selectedCustomer"
                    id="ajax"
                    label="groupName"
                    track-by="groupName"
                    placeholder="Type to search"
                    open-direction="bottom"
                    :options="customers"
                    :multiple="false"
                    :searchable="true"
                    :internal-search="false"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :options-limit="300"
                    :limit="3"
                    :limit-text="limitText"
                    :max-height="800"
                    :show-no-results="false"
                    :hide-selected="true"
                    @search-change="apifetch"
                    required
                  >
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="custom__tag">
                        <span>{{ option.groupName }}</span>
                        <span class="custom__remove" @click="remove(option)"
                          >❌</span
                        >
                      </span>
                    </template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="col-12 no--padding">
              <div class="form__item">
                <label class="form__label">Product</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter Account Recipient Name"
                  readonly
                  required
                  v-model="account.depositProduct"
                />
              </div>
            </div>
            <div class="form__item">
              <div class="form__action">
                <button
                  data-dismiss="#newDepositModal"
                  type="button"
                  class="button form__button form__button--lg w-30 save-changes"
                  :disabled="!isInputFilled"
                  @click="depositAccountFormCreate"
                >
                  continue
                </button>
                <a
                  data-dismiss="#newDepositModal"
                  role="button"
                  class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  @click="closeCreateAccountModal()"
                >
                  cancel
                </a>
              </div>
            </div>
          </div>
          <div v-show="nextDepositForm">
            <div class="row">
              <div class="col-12">
                <div class="form__item">
                  <label class="form__label">Display Name</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    v-model="deposit.savingsAccountName"
                    placeholder="Enter Display Name"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Product Type</label>
                  <p class="text--black text--600 text--capital text--regular">
                    {{ account.depositSetting.productType }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">currency</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    v-model="account.depositSetting.currency"
                    readonly
                    placeholder="Currency"
                  />
                </div>
              </div>
            </div>
            <div class="form__item">
              <p class="text--black text--600 text--capital text--small">
                {{ account.depositSetting.productDescription }}
              </p>
            </div>

            <ul id="accordion" class="mb5">
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#accountTerms"
                  aria-expanded="false"
                  aria-controls="accountTerms"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Account Terms
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="accountTerms"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div v-show="fixedTermR" class="row">
                      <div class="col-12">
                        <div class="form__item">
                          <label class="form__label">Interest Rate</label>
                          <div v-if="interestError.length">
                            <div v-for="error in interestError" :key="error.id">
                              <span
                                class="text--tiny d-block text--left text--600 mb-2 text--danger"
                                >{{ error }}</span
                              >
                            </div>
                          </div>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span
                                class="w-100 d-flex text--medium text-white text--600 align-items-center justify-content-center"
                              >
                                %
                              </span>
                            </div>
                            <input
                              type="number"
                              class="input input--right form__input form__input--lg"
                              placeholder="Enter interest rate per month"
                              :min="
                                account.depositSetting
                                  .minInterestRateConstraints
                              "
                              :max="
                                account.depositSetting
                                  .maxInterestRateConstraints
                              "
                              v-model.number="deposit.interestRate"
                            />
                          </div>
                          <small
                            class="text--tiny d-block text--right text--600 mt-2"
                            >Min:
                            {{
                              account.depositSetting.minInterestRateConstraints
                            }}
                            Max:
                            {{
                              account.depositSetting.maxInterestRateConstraints
                            }}
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            {{ account.depositSetting.interestChargeMethod }}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >Interest Calculated Using</label
                          >
                          <p
                            class="text--black text--600 text--capital text--regular"
                          >
                            {{
                              account.depositSetting.calculationAccountBalance
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >Interest paid into account</label
                          >
                          <p
                            class="text--black text--600 text--capital text--regular"
                          >
                            {{ account.depositSetting.interestPaymentPeriod }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >Single Withdrawal Limit</label
                          >
                          <input
                            type="number"
                            class="input input--right form__input form__input--lg"
                            min="0"
                            placeholder="Enter Single Withdrawal Limit"
                            v-model="deposit.maxWithdrawalAmount"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >Recommended Deposit Amount</label
                          >
                          <input
                            type="number"
                            class="input input--right form__input form__input--lg"
                            min="0"
                            placeholder="Enter Recommended Deposit"
                            v-model="deposit.defaultDepositAmount"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form__item">
                      <label class="form__label">Interest Rate Terms</label>
                      <p
                        class="text--black text--600 text--capital text--regular"
                      >
                        {{ account.depositSetting.interestRateTerms }}
                      </p>
                    </div>

                    <div v-show="tierBalance" class="row">
                      <div class="col-12">
                        <div
                          class="form__item p-5 border border-radius"
                          v-for="(balanceTier, counter) in account
                            .depositSetting.tiers"
                          :key="counter"
                        >
                          <p
                            class="text--primary form__item_text text--regular mb-5"
                          >
                            Tiered Per Balance {{ counter + 1 }}
                          </p>
                          <div class="row">
                            <div class="col-6">
                              <label class="form__label"> From </label>
                              <input
                                type="number"
                                placeholder="0.00"
                                class="input form__input form__input--lg"
                                v-model.number="balanceTier.fromTier"
                                min="0.00"
                                readonly
                              />
                            </div>
                            <div class="col-6">
                              <label class="form__label"> To </label>
                              <input
                                :value="inputCurrencyFormat(balanceTier.toTier)"
                                type="text"
                                min="0.00"
                                placeholder="0.00"
                                @keyup="
                                  productBalanceTierChange(
                                    $event,
                                    balanceTier.id
                                  )
                                "
                                class="input form__input form__input--lg"
                                readonly
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <div class="form__item">
                                <label class="form__label">
                                  {{
                                    account.depositSetting.interestChargeMethod
                                  }}
                                </label>
                                <input
                                  type="number"
                                  placeholder="Enter Amount"
                                  class="input form__input form__input--lg"
                                  v-model.number="balanceTier.interestTierValue"
                                  min="0"
                                  readonly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-show="tierPeriod" class="row">
                      <div class="col-12">
                        <div
                          class="form__item p-5 border border-radius"
                          v-for="(balanceTier, counter) in account
                            .depositSetting.tiers"
                          :key="counter"
                        >
                          <p
                            class="text--primary form__item_text text--regular mb-5"
                          >
                            Tiered Per Period {{ counter + 1 }}
                          </p>
                          <div class="row">
                            <div class="col-6">
                              <label class="form__label"> From </label>
                              <input
                                type="number"
                                placeholder="0.00"
                                class="input form__input form__input--lg"
                                v-model.number="balanceTier.fromTier"
                                min="0.00"
                                readonly
                              />
                            </div>
                            <div class="col-6">
                              <label class="form__label"> To </label>
                              <input
                                :value="inputCurrencyFormat(balanceTier.toTier)"
                                type="text"
                                min="0.00"
                                placeholder="0.00"
                                @keyup="
                                  productBalanceTierChange(
                                    $event,
                                    balanceTier.id
                                  )
                                "
                                class="input form__input form__input--lg"
                                readonly
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <div class="form__item">
                                <label class="form__label">
                                  {{
                                    account.depositSetting.interestChargeMethod
                                  }}
                                </label>
                                <input
                                  type="number"
                                  placeholder="Enter Amount"
                                  class="input form__input form__input--lg"
                                  v-model.number="balanceTier.interestTierValue"
                                  min="0"
                                  readonly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#decriptions"
                  aria-expanded="false"
                  aria-controls="decriptions"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Notes
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="decriptions" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="form__item">
                      <textarea
                        cols="5"
                        rows="5"
                        class="input form__input"
                        placeholder="Product Descriptions"
                        v-model.trim="deposit.savingsAccountNotes"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </ul>

            <div class="form__item">
              <div class="form__action">
                <button
                  class="button form__button form__button--lg w-30 save-changes"
                  @click.prevent="createDepositAccount"
                  v-if="loading"
                >
                  <div class="spinner-border texxt-light" role="status"></div>
                </button>
                <button
                  class="button form__button form__button--lg save-changes"
                  @click.prevent="createDepositAccount"
                  v-else
                >
                  create deposit account
                </button>
                <a
                  data-dismiss="#newDepositModal"
                  role="button"
                  class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  @click="closeCreateAccountModal"
                >
                  cancel
                </a>
              </div>
            </div>
          </div>
        </form>
      </template>
    </createAccount>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </main>
  <!-- </div> -->
</template>

<script>
import SideModal from "@/components/SideModal/_base-sideModal.vue";
import EditModal from "@/components/SideModal/_base-editSideModal.vue";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
import TextArea from "@/components/Inputs/_base-text-area.vue";
import BaseInput from "@/components/Inputs/_base-Input.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import {
  CREATE_PRODUCT,
  GET_ALL_DEPOSITS,
  UPDATE_DEPOSIT_PRODUCT,
  DEACTIVATE_PRODUCT,
} from "@/core/services/store/deposit.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import branchesService from "@/core/services/organization-service/branches.service";
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
import GeneralApiService from "@/core/services/general.service";
import moment from "moment";
import createAccount from "@/components/deposit/_base-createAccount.vue";
import currencyMixin from "@/mixins/currencyMixin";
import { Role } from "@/core/services/role.js";
// import KTAside from "@/view/layout/aside/Aside.vue";

export default {
  mixins: [validationMixin, currencyMixin],
  name: "Deposit",
  data: function () {
    return {
      trackBy: "branchKey",
      initialBranches: [],
      selectedCustomer: "",
      customers: [],
      isLoading: false,
      allBranches: [],
      showCreateModal: false,
      showDeleteModal: false,
      showCreateAccountModal: false,
      showConfirmModal: false,
      pages: 1,
      size: 10,
      productkey: "",
      productState: false,
      allProds: this.products,
      loading: false,
      interestRate: "",
      content_header: {
        title: "Deposit",
        subTitle:
          "Manage the products offerings that you have available to your customers by creating and editing deposit products.",
      },
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Deposit Product has been created",
      },
      fetchModel: {
        branchName: null,
        branchState: "Active",
        pageIndex: 1,
        pageSize: 1000,
      },
      form: {
        productName: "",
        productID: "",
        productCategory: "",
        productType: "",
        interestPerDays: 0,
        maxInterestCalculationBalance: 0,
        defaultDepositAmount: 0,
        maxWithdrawalAmount: 0,
        dailyWithdrawalLimit: 0,
        defaultOpeningBalance: "",
        minOpeningBalance: "",
        maxOpeningBalance: "",
        productState: false,
        productDescription: "",
        idType: "",
        idTemplate: null,
        startingFrom: 0,
        currency: "",
        interestPaidIntoAccount: false,
        interestRateTerms: "",
        interestChargeMethod: "",
        calculationAccountBalance: "",
        interestPaymentPeriod: "",
        interestDaysInYears: "",
        defaultInterestRateConstraints: 0,
        minInterestRateConstraints: 0,
        maxInterestRateConstraints: 0,
        balanceTiers: [],
        periodTiers: [],
        defaultTermLength: "",
        minTermLength: "",
        maxTermLength: "",
        termUnit: "",
        autoDormant: false,
        // autoDormat: false,
        active: false,
        autoDormantAfter: "",
        // AutoDormantAfter: "",
        allowArbitaryFees: false,
        showInactiveFees: true,
        fees: [],
        branches: [],
        forAllBranches: false,
        availablity: [],
        createdBy: "Jude",
        modifiedBy: "Jude",
        tiers: [],
        savingsGoalTarget: "",
      },
      account: {
        accountType: "",
        accountName: "",
        depositProduct: "",
        interestRate: "",
        depositSetting: [],
      },
      deposit: {
        accountHolderKey: "",
        accountHolderType: "",
        accountType: "",
        assignedBranchKey: "",
        assignedCenterKey: "",
        assignedUserKey: "",
        currencyCode: "",
        interestSettingsKey: "",
        savingsAccountName: "",
        savingsAccountNotes: "",
        savingsProductKey: "",
        interestRate: "",
        maxWithdrawalAmount: "",
        dailyWithdrawalLimit: "",
        defaultDepositAmount: "",
      },
      pagesizes: [
        {
          size: 10,
        },
        {
          size: 20,
        },
        {
          size: 50,
        },
      ],
      cats: [
        {
          value: "Name",
          text: "Product Name",
        },
        {
          value: "Type",
          text: "Product Type",
        },
        {
          value: "ModifiedDate",
          text: "Last Modified",
        },
      ],
      monthly: false,
      fixedTerm: false,
      balancedTier: false,
      periodTiered: false,
      deactivatedProduct: "",
      AccountSetting: false,
      template: false,
      balanceCalc: false,
      showEditModal: false,
      showCopyProductModal: false,
      id: "",
      days: false,
      branches: true,
      allBranchName: [],
      showBranches: false,
      productType: false,
      depProduct: false,
      depProd: [],
      openBalance: false,
      maxDepo: false,
      depositForm: true,
      nextDepositForm: false,
      tierBalance: false,
      tierPeriod: false,
      fixedTermR: false,
      allProd: [],
      interestError: [],
      showConfirmSave: false,
      showConfirmUpdate: false,
      sort: "",
      state: false,
      depProdState: "",
      filter_by_label: "Sort By <ion-icon name='caret-down-outline'/>",
      showDeleteProductModal: false,
      showCreateInactiveFeeData: [],
      showEditInactiveFeeData: [],
      searchProductName: "",
    };
  },
  validations() {
    if (!this.form.interestPaidIntoAccount) {
      return {
        form: {
          productName: {
            required,
          },
          productID: {
            required,
          },
          productCategory: {
            required,
          },
          productType: {
            required,
          },
          productDescription: {
            required,
          },
          currency: {
            required,
          },
          idTemplate: {
            required,
          },
          idType: {
            required,
          },
        },
      };
    }
    if (this.form.interestPaidIntoAccount) {
      return {
        form: {
          productName: {
            required,
          },
          productID: {
            required,
          },
          productCategory: {
            required,
          },
          productType: {
            required,
          },
          productDescription: {
            required,
          },
          currency: {
            required,
          },
          idTemplate: {
            required,
          },
          idType: {
            required,
          },
          interestRateTerms: {
            required,
          },
          interestPaymentPeriod: {
            required,
          },
          interestChargeMethod: {
            required,
          },
          calculationAccountBalance: {
            required,
          },
        },
      };
    }
  },
  components: {
    SideModal,
    EditModal,
    DeleteModal,
    TextArea,
    BaseInput,
    Multiselect,
    createAccount,
    Pagination: () =>
      import(
        /* WebpackChunkName: "Pagination" */ "@/components/UI/pagination.vue"
      ),
    // KTAside
  },
  methods: {
    performSearch() {
      this.$store.dispatch(GET_ALL_DEPOSITS, {
        pageindex: this.products.currentPage,
        pagesize: this.size,
        status: this.productState,
        sort: this.sort,
        search: this.searchProductName,
      });
    },
    goToDashboard() {
      this.$router.push({ path: "/dashboard" });
    },
    ...mapActions([
      "GET_ALL_PRODUCT_TYPE",
      "GET_ALL_PRODUCT_CATEGORY",
      "GET_ALL_CURRENCY",
      "GET_ALL_INTEREST_CHARGE",
      "GET_ALL_INTEREST_RATE",
      "GET_ALL_ID",
      "GET_DAYS_IN_YEARS",
      "GET_ACCOUNT_BALANCE_CALCULATION",
      "GET_ACCOUNT_INTEREST",
      "GET_MONTHS",
      "GET_FEE_TYPE",
      "GET_APPLIED_DATE_METHOD",
      "CREATE_PRODUCT",
      "GET_PRODUCT_AVAILABILITY",
      "GET_TERM_UNIT",
    ]),
    nameFormatter({ branchKey, branchName }) {
      return `${branchKey} - ${branchName}`;
    },
    interestHandler() {
      if (
        this.form.interestRateTerms === "Fixed" ||
        this.form.interestRateTerms === "FIXED"
      ) {
        this.fixedTerm = true;
        this.balancedTier = false;
        this.periodTiered = false;
      } else if (
        this.form.interestRateTerms === "Tier per balance" ||
        this.form.interestRateTerms === "Tier Per Balance"
      ) {
        this.fixedTerm = false;
        this.balancedTier = true;
        this.periodTiered = false;
      } else if (
        this.form.interestRateTerms === "Tier per period" ||
        this.form.interestRateTerms === "Tier Per Period"
      ) {
        this.fixedTerm = false;
        this.balancedTier = false;
        this.periodTiered = true;
      } else {
        this.fixedTerm = false;
        this.balancedTier = false;
        this.periodTiered = false;
      }
    },
    newAccountSettings() {
      if (
        this.form.idType === "Incremental_Number" ||
        this.form.idType === "Incremental Number"
      ) {
        this.AccountSetting = true;
        this.template = false;
      } else if (
        this.form.idType === "Random_Pattern" ||
        this.form.idType === "Random Pattern"
      ) {
        this.template = true;
        this.AccountSetting = false;
      } else {
        this.template = false;
        this.AccountSetting = false;
      }
    },
    feeChange() {
      if (this.form.fees.length !== undefined || this.form.fees.length > 0) {
        for (var i = 0; i < this.form.fees.length; i++) {
          if (this.form.fees[i].feeType === "Manual") {
            this.form.fees[i].feeMonthly = false;
          } else {
            this.form.fees[i].feeMonthly = true;
          }
        }
      }
    },
    reset() {
      this.form = {
        productName: "",
        productID: "",
        productCategory: "",
        productType: "",
        interestPerDays: 0,
        maxInterestCalculationBalance: 0,
        defaultDepositAmount: 0,
        maxWithdrawalAmount: 0,
        dailyWithdrawalLimit: 0,
        defaultOpeningBalance: "",
        minOpeningBalance: "",
        maxOpeningBalance: "",
        productState: false,
        productDescription: "",
        idType: "",
        idTemplate: "",
        currency: "",
        interestPaidIntoAccount: false,
        interestRateTerms: "",
        interestChargeMethod: "",
        calculationAccountBalance: "",
        interestPaymentPeriod: "",
        interestDaysInYears: "",
        defaultInterestRateConstraints: 0,
        minInterestRateConstraints: 0,
        maxInterestRateConstraints: 0,
        balanceTiers: [],
        periodTiers: [],
        defaultTermLength: "",
        minTermLength: "",
        maxTermLength: "",
        termUnit: "",
        autoDormant: false,
        active: false,
        autoDormantAfter: "",
        allowArbitaryFees: false,
        showInactiveFees: true,
        fees: [],
        branches: [],
        forAllBranches: false,
        availablity: [],
        createdBy: "Jude",
        modifiedBy: "Jude",
        tiers: [],
      };
    },
    checkProductType() {
      if (this.form.productType === "Saving Plan") {
        this.maxDepo = false;
        this.productType = true;
        this.openBalance = false;
        this.depProduct = true;
      } else if (this.form.productType === "Saving Account") {
        this.depProduct = true;
        this.productType = false;
        this.openBalance = false;
        this.maxDepo = true;
      } else if (this.form.productType === "Fixed Deposit") {
        this.openBalance = true;
        this.depProduct = false;
        this.productType = true;
        this.maxDepo = false;
      } else {
        this.productType = false;
        this.depProduct = false;
        this.openBalance = false;
        this.maxDepo = false;
      }
    },
    handleChange() {
      if (
        this.form.interestPaymentPeriod === "Every_Month" ||
        this.form.interestPaymentPeriod === "Every Month"
      ) {
        this.monthly = true;
      } else {
        this.monthly = false;
      }
    },
    changeBalanceCalculation() {
      if (
        this.form.calculationAccountBalance === "End_Of_Day_Balance" ||
        this.form.calculationAccountBalance === "End Of The Day Balance"
      ) {
        this.balanceCalc = true;
      } else {
        this.balanceCalc = false;
      }
    },
    addTag(newTag) {
      const tag = {
        branchName: newTag,
        branchKey: newTag,
      };
      this.options.push(tag);
      // this.value.push(tag)
    },
    updateBranches(allBranches) {
      let allBranchName = [];
      allBranches.forEach((branch) => {
        allBranchName.push(branch.branchKey);
      });
      this.form.branches = allBranchName;
      this.copyProduct.branches = allBranchName;
    },
    addBranches(e) {
      if (e.target.checked !== true) {
        this.branches = true;
        this.form.forAllBranches = false;
        this.copyProduct.forAllBranches = false;
      } else {
        this.branches = false;
        this.form.forAllBranches = false;
        this.copyProduct.forAllBranches = false;
        this.allBranches = [];
      }
    },
    percentHandler() {
      if (
        this.form.interestChargeMethod === "% Per X Days" ||
        this.form.interestChargeMethod === "% Per X Days"
      ) {
        this.days = true;
      } else {
        this.days = false;
      }
    },
    addFee() {
      this.form.fees.push({
        feeName: "",
        feeType: "",
        feePayment: "Flat",
        feeAmount: "",
        feeMonthly: false,
        activate: false,
        feeApplyMethod: "",
        feeIncome: "",
        feeKey: "",
        id: this.form.fees.length + 1,
      });
    },
    showOnlyInactive(type) {
      if (type == "create") {
        this.showCreateInactiveFeeData = this.form.fees.filter(
          (item) => item.activate
        );
      }

      if (type == "edit") {
        this.showEditInactiveFeeData = this.form.fees.filter(
          (item) => item.activate
        );
      }
    },
    updateActivate(fee, type) {
      if (type == "create") {
        this.form.fees.forEach((item) => {
          if (item.id === fee.id) {
            item.activate = fee.activate;
          }
        });
        this.showCreateInactiveFeeData = this.form.fees.filter(
          (item) => item.activate == true
        );
      }

      if (type == "edit") {
        this.form.fees.forEach((item) => {
          if (item.feeKey === fee.feeKey) {
            item.activate = fee.activate;
          }
        });
        this.showEditInactiveFeeData = this.form.fees.filter(
          (item) => item.activate == true
        );
      }
    },
    deleteFee(counter) {
      this.form.fees.splice(counter, 1);
    },
    addBalanceTiers() {
      if (this.form.balanceTiers.length < 1) {
        this.form.balanceTiers.push({
          id: 1,
          fromTier: 0,
          toTier: 0,
          interestTierAmount: 0.0,
        });
      } else {
        this.form.balanceTiers.push({
          id: 2,
          fromTier:
            this.form.balanceTiers[this.form.balanceTiers.length - 1].toTier +
            0.01,
          toTier: 0,
          interestTierAmount: 0.0,
        });
      }
    },
    updateBalanceTiers() {
      if (this.form.balanceTiers.length < 1) {
        this.form.balanceTiers.push({
          id: 1,
          fromTier: 0,
          toTier: 0,
          interestTierAmount: 0.0,
        });
      } else {
        this.form.balanceTiers.push({
          id: this.form.balanceTiers.length,
          fromTier:
            this.form.balanceTiers[this.form.balanceTiers.length - 1].toTier +
            0.01,
          toTier: 0,
          interestTierValue: 0.0,
        });
      }
    },
    deleteBalanceTiers(counter) {
      this.form.balanceTiers.splice(counter, 1);
    },
    productFeeAmountChange(e, id) {
      const formatAndValidateFees = (amount) => {
        const amountValue = amount;
        const amtPaid = this.convertFigureToNumber(amountValue);
        const amountInputted = this.inputCurrencyFormat(amtPaid);
        if (this.validateDepositAmount(amountValue)) {
          this.form.fees[
            this.form.fees.findIndex((item) => item.id == id)
          ].feeAmount = amtPaid;
          return amountInputted;
        }
        this.form.fees[
          this.form.fees.findIndex((item) => item.id == id)
        ].feeAmount = "";
      };
      formatAndValidateFees(e.target.value);
    },
    productFeeAmountChangeEdit(value, feeKey, type, id) {
      const formatAndValidateFees = (amount) => {
        const amountValue = `${amount}`;
        const amtPaid = this.convertFigureToNumber(amountValue);
        if (this.validateDepositAmount(amountValue)) {
          let idx = 0;
          if (feeKey) {
            idx = this.form.fees.findIndex((item) => item.feeKey == feeKey);
          } else {
            idx = this.form.fees.findIndex((item) => item.id == id);
          }
          this.form.fees[idx].feeAmount = amtPaid;
          this.form.fees[idx].feeAmount = amountValue;
          this.showOnlyInactive(type);
          return;
        }
        this.form.fees[
          this.form.fees.findIndex((item) => item.feeKey == feeKey)
        ].feeAmount = "";
        this.showOnlyInactive(type);
      };
      formatAndValidateFees(value);
    },
    productBalanceTierChange(e, id) {
      const formatAndValidateFees = (amount) => {
        const amountValue = amount;
        const amtPaid = this.convertFigureToNumber(amountValue);
        const amountInputted = this.inputCurrencyFormat(amtPaid);
        if (this.validateDepositAmount(amountValue)) {
          this.form.balanceTiers[
            this.form.balanceTiers.findIndex((item) => item.id == id)
          ].toTier = amtPaid;
          // this.form.fees[this.form.fees.findIndex(item => item.id == id)].feeAmount = amtPaid;
          return amountInputted;
        }
        this.form.balanceTiers[
          this.form.balanceTiers.findIndex((item) => item.id == id)
        ].toTier = "";
      };
      formatAndValidateFees(e.target.value);
    },
    addPeriodTiers() {
      if (this.form.periodTiers.length < 1) {
        this.form.periodTiers.push({
          id: "string",
          fromTier: 0,
          toTier: 0,
          interestTierValue: 0,
        });
      } else {
        this.form.periodTiers.push({
          id: "string",
          fromTier:
            this.form.periodTiers[this.form.periodTiers.length - 1].toTier + 1,
          toTier: 0,
          interestTierValue: 0,
        });
      }
    },
    updatePeriodTiers() {
      if (this.form.periodTiers.length < 1) {
        this.form.periodTiers.push({
          id: "string",
          fromTier: 0,
          toTier: 0,
          interestTierValue: 0,
        });
      } else {
        this.form.periodTiers.push({
          id: "string",
          fromTier:
            this.form.periodTiers[this.form.periodTiers.length - 1].toTier + 1,
          toTier: 0,
          interestTierValue: 0,
        });
      }
    },
    deletePeriodTiers(counter) {
      this.form.periodTiers.splice(counter, 1);
    },
    onSave() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      } else {
        this.showConfirmSave = true;
      }
    },
    update() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      } else {
        this.showConfirmUpdate = true;
      }
    },
    closeConfirmSave() {
      this.showConfirmSave = false;
    },
    closeConfirmUpdate() {
      this.showConfirmUpdate = false;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      } else {
        this.loading = true;

        let productName = this.form.productName;
        let productID = this.form.productID;
        let productCategory = this.form.productCategory;
        let productType = this.form.productType;
        let interestPerDays = this.form.interestPerDays
          ? this.form.interestPerDays
          : 0;
        let maxInterestCalculationBalance = this.form
          .maxInterestCalculationBalance
          ? this.form.maxInterestCalculationBalance
          : 0;
        let defaultDepositAmount = this.form.defaultDepositAmount
          ? this.form.defaultDepositAmount
          : 0;
        let maxWithdrawalAmount = this.form.maxWithdrawalAmount
          ? this.form.maxWithdrawalAmount
          : 0;
        let dailyWithdrawalLimit = this.form.dailyWithdrawalLimit
          ? this.form.dailyWithdrawalLimit
          : 0;
        let defaultOpeningBalance = this.form.defaultOpeningBalance
          ? this.form.defaultOpeningBalance
          : 0;
        let minOpeningBalance = this.form.minOpeningBalance
          ? this.form.minOpeningBalance
          : 0;
        let maxOpeningBalance = this.form.maxOpeningBalance
          ? this.form.maxOpeningBalance
          : 0;
        let productState = this.form.productState;
        let productDescription = this.form.productDescription
          ? this.form.productDescription
          : "";
        let idType = this.form.idType;
        let idTemplate = this.form.idTemplate;
        let currency = this.form.currency;
        let interestPaidIntoAccount = this.form.interestPaidIntoAccount;
        let branches = this.form.branches;
        let forAllBranches = this.form.forAllBranches;
        let interestRateTerms = this.form.interestRateTerms
          ? this.form.interestRateTerms
          : "";
        let interestChargeMethod = this.form.interestChargeMethod
          ? this.form.interestChargeMethod
          : "";
        let calculationAccountBalance = this.form.calculationAccountBalance
          ? this.form.calculationAccountBalance
          : "";
        let interestPaymentPeriod = this.form.interestPaymentPeriod
          ? this.form.interestPaymentPeriod
          : "";
        let interestDaysInYears = this.form.interestDaysInYears
          ? this.form.interestDaysInYears
          : "";
        let defaultInterestRateConstraints = this.form
          .defaultInterestRateConstraints
          ? this.form.defaultInterestRateConstraints
          : 0;
        let minInterestRateConstraints = this.form.minInterestRateConstraints
          ? this.form.minInterestRateConstraints
          : 0;
        let maxInterestRateConstraints = this.form.maxInterestRateConstraints
          ? this.form.maxInterestRateConstraints
          : 0;
        let balanceTiers = this.form.balanceTiers;
        let periodTiers = this.form.periodTiers;
        let defaultTermLength = this.form.defaultTermLength
          ? this.form.defaultTermLength
          : 0;
        let minTermLength = this.form.minTermLength
          ? this.form.minTermLength
          : 0;
        let maxTermLength = this.form.maxTermLength
          ? this.form.maxTermLength
          : 0;
        let termUnit = this.form.termUnit ? this.form.termUnit : "";
        let autoDormant = this.form.autoDormant;
        let autoDormantAfter = this.form.autoDormantAfter
          ? this.form.autoDormantAfter
          : 0;
        let allowArbitaryFees = this.form.allowArbitaryFees;
        let showInactiveFees = this.form.showInactiveFees;
        let fees = this.form.fees;
        let availablity = this.form.availablity;
        let createdBy = this.form.createdBy;
        let modifiedBy = this.form.modifiedBy;
        let active = this.form.productState;
        this.$store
          .dispatch(CREATE_PRODUCT, {
            productName,
            productID,
            productCategory,
            productType,
            interestPerDays,
            maxInterestCalculationBalance,
            defaultDepositAmount,
            maxWithdrawalAmount,
            dailyWithdrawalLimit,
            defaultOpeningBalance,
            minOpeningBalance,
            maxOpeningBalance,
            productState,
            productDescription,
            idType,
            idTemplate,
            currency,
            interestPaidIntoAccount,
            interestRateTerms,
            interestChargeMethod,
            calculationAccountBalance,
            interestPaymentPeriod,
            interestDaysInYears,
            defaultInterestRateConstraints,
            minInterestRateConstraints,
            maxInterestRateConstraints,
            balanceTiers,
            periodTiers,
            defaultTermLength,
            minTermLength,
            maxTermLength,
            termUnit,
            autoDormant,
            autoDormantAfter,
            allowArbitaryFees,
            showInactiveFees,
            fees,
            branches,
            forAllBranches,
            availablity,
            createdBy,
            modifiedBy,
            active,
          })
          .then(() => {
            this.loading = false;
            let desc = "Product succesfully created!";
            this.alertSuccess(desc);
            this.closeCreateModal();
            this.closeConfirmSave();
            this.showCopyProductModal = false;
            this.getDeposits();
            this.prefillForm();
            this.resetCopyProductForm();
          })
          .catch((e) => {
            this.loading = false;
            this.showConfirmSave = false;
            this.alertError(e.response.data.message);
          });
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    getDeposits() {
      this.$store.dispatch(GET_ALL_DEPOSITS, {
        pageindex: this.pages,
        pagesize: this.size,
        status: this.state,
        sort: this.sort,
        search: this.searchProductName,
      });
    },
    changeCurrentPage(pages = null) {
      if (pages === "next") {
        let nextPage = this.allDeposits.hasNext
          ? this.allDeposits.currentPage + 1
          : 0;
        this.$store.dispatch(GET_ALL_DEPOSITS, {
          pageindex: nextPage,
          pagesize: this.size,
          status: this.state,
          sort: this.sort,
          search: this.searchProductName,
        });
      } else if (pages === "previous") {
        let nextPage = this.allDeposits.hasPrevious
          ? this.allDeposits.currentPage - 1
          : 0;
        this.$store.dispatch(GET_ALL_DEPOSITS, {
          pageindex: nextPage,
          pagesize: this.size,
          status: this.state,
          sort: this.sort,
          search: this.searchProductName,
        });
      } else if (pages !== null) {
        this.$store.dispatch(GET_ALL_DEPOSITS, {
          pageindex: pages,
          pagesize: this.size,
          status: this.state,
          sort: this.sort,
          search: this.searchProductName,
        });
      }
    },
    openCreateModal() {
      this.prefillForm();
      this.maxDepo = false;
      this.productType = false;
      this.openBalance = false;
      this.depProduct = false;
      this.showCreateModal = true;
    },
    closeCreateModal() {
      this.showCreateModal = false;
      this.prefillForm();
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
    openEditModal() {
      this.checkProductType();
      this.showEditModal = true;
    },
    openConfirmModal() {
      this.showConfirmModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.showDeleteProductModal = false;
    },
    cancel() {
      this.showEditModal = false;
      this.reset();
    },
    deactivate(allDeposit, status) {
      if (allDeposit) this.productkey = allDeposit.id;
      if (status == true) this.showDeleteModal = !this.showDeleteModal;
      this.productState = allDeposit.productState;
      if (status == false)
        this.showDeleteProductModal = !this.showDeleteProductModal;
    },
    activate(allDeposit) {
      if (allDeposit) this.productkey = allDeposit.id;
      this.productState = allDeposit.productState;
      this.showConfirmModal = !this.showConfirmModal;
    },
    deactivateStatus() {
      let productState = !this.productState;
      let savingproductkey = this.productkey;
      const savings_account_key = this.productkey;
      this.loading = true;
      if (this.showDeleteProductModal == false) {
        this.$store
          .dispatch(DEACTIVATE_PRODUCT, {
            productState,
            savingproductkey,
          })
          .then(() => {
            // this.showCreateModal = false,
            if (this.deactivatedProduct) {
              this.productState = "";
            } else {
              this.productState = true;
            }
            this.loading = false;
            let desc = "Product status updated!";
            this.showDeleteModal = false;
            this.alertSuccess(desc);
            this.getDeposits();
          })
          .catch((error) => {
            if (this.deactivatedProduct) {
              this.productState = "";
            } else {
              this.productState = true;
            }
            this.loading = false;
            this.alertError(error.response.data.message);
          });
      } else {
        ApiService.delete(
          `DepositAdministration/DeleteDepositProduct?depositProductId=${savings_account_key}`
        )
          .then((response) => {
            this.loading = false;
            this.showDeleteProductModal = !this.showDeleteProductModal;
            this.alertSuccess(response.data.message);
            this.getDeposits();
          })
          .catch((error) => {
            this.loading = false;
            this.alertError(error.response.data.message);
          });
      }
    },
    activateStatus() {
      let savingproductkey = this.productkey;
      let productState = !this.productState;
      // if (this.deactivatedProduct) {
      //   productSt = "";
      // } else {
      //   productSt = true;
      // }
      this.loading = true;
      this.$store
        .dispatch(DEACTIVATE_PRODUCT, {
          productState,
          savingproductkey,
        })
        .then(() => {
          // this.showCreateModal = false,
          if (this.deactivatedProduct) {
            this.productState = "";
          } else {
            this.productState = true;
          }
          this.loading = false;
          let desc = "Product status updated!";
          this.showConfirmModal = false;
          this.alertSuccess(desc);
          this.getDeposits();
        })
        .finally(() => {
          this.getDeposits();
        });
    },
    resetCopyProductForm() {
      this.copyProduct = {};
    },
    fillCopyProduct() {
      this.form.productName = this.depProd.productName + "_Copy";
      this.form.productID = this.depProd.productID + "_Copy";
      this.form.productCategory = this.depProd.productCategory;
      this.form.productType = this.depProd.productType;
      if (
        this.form.productType === "Saving_Plan" ||
        this.form.productType === "Saving Plan"
      ) {
        this.productType = true;
        this.depProduct = true;
        this.openBalance = false;
        this.maxDepo = false;
      } else if (
        this.form.productType === "Savings_Account" ||
        this.form.productType === "Saving Account"
      ) {
        this.depProduct = true;
        this.productType = false;
        this.maxDepo = true;
        this.openBalance = false;
      } else if (
        this.form.productType === "Fixed_Deposit" ||
        this.form.productType === "Fixed Deposit"
      ) {
        this.depProduct = false;
        this.productType = true;
        this.openBalance = true;
        this.maxDepo = false;
      } else {
        this.productType = false;
        this.depProduct = false;
        this.openBalance = false;
        this.maxDepo = false;
      }
      this.form.interestPerDays = this.depProd.interestPerDays;
      this.form.maxInterestCalculationBalance =
        this.depProd.maxInterestCalculationBalance;
      this.form.defaultDepositAmount = this.depProd.defaultDepositAmount;
      this.form.maxWithdrawalAmount = this.depProd.maxWithdrawalAmount;
      this.form.dailyWithdrawalLimit = this.depProd.dailyWithdrawalLimit;
      this.form.defaultOpeningBalance = this.depProd.defaultOpeningBalance;
      this.form.minOpeningBalance = this.depProd.minOpeningBalance;
      this.form.maxOpeningBalance = this.depProd.maxOpeningBalance;
      this.form.productState = this.depProd.productState;
      this.form.productDescription = this.depProd.productDescription;
      this.form.idType = this.depProd.idType;
      if (this.form.idType === "Random Pattern") {
        this.template = true;
      }
      this.form.idTemplate = this.depProd.idTemplate;
      this.form.currency = this.depProd.currency;
      this.form.interestPaidIntoAccount = this.depProd.interestPaidIntoAccount;
      this.form.interestRateTerms = this.depProd.interestRateTerms;
      this.form.interestChargeMethod = this.depProd.interestChargeMethod;
      if (this.form.interestChargeMethod === "% Per X Days") {
        this.days = true;
      } else {
        this.days = false;
      }
      this.form.calculationAccountBalance =
        this.depProd.calculationAccountBalance;
      if (this.form.calculationAccountBalance === "End Of The Day Balance") {
        this.balanceCalc = true;
      } else {
        this.balanceCalc = false;
      }
      this.form.interestPaymentPeriod = this.depProd.interestPaymentPeriod;
      if (this.form.interestPaymentPeriod === "Every Month") {
        this.monthly = true;
      } else {
        this.monthly = false;
      }
      this.form.interestDaysInYears = this.depProd.interestDaysInYears;
      this.form.defaultInterestRateConstraints =
        this.depProd.defaultInterestRateConstraints;
      this.form.minInterestRateConstraints =
        this.depProd.minInterestRateConstraints;
      this.form.maxInterestRateConstraints =
        this.depProd.maxInterestRateConstraints;
      this.form.tiers = this.depProd.tiers;
      if (
        this.form.interestRateTerms === "Fixed" ||
        this.form.interestRateTerms === "FIXED"
      ) {
        this.fixedTerm = true;
        this.balancedTier = false;
        this.periodTiered = false;
      } else if (
        this.form.interestRateTerms === "Tier per balance" ||
        this.depProd.interestRateTerms === "Tier per balance"
      ) {
        this.fixedTerm = false;
        this.balancedTier = true;
        this.periodTiered = false;
        this.form.balanceTiers = this.depProd.tiers;
      } else if (
        this.form.interestRateTerms === "Tier per period" ||
        this.depProd.interestRateTerms === "Tier per period"
      ) {
        this.fixedTerm = false;
        this.balancedTier = false;
        this.periodTiered = true;
        this.form.periodTiers = this.depProd.tiers;
      } else {
        this.fixedTerm = false;
        this.balancedTier = false;
        this.periodTiered = false;
      }
      this.form.defaultTermLength = this.depProd.defaultTermLength;
      this.form.minTermLength = this.depProd.minTermLength;
      this.form.maxTermLength = this.depProd.maxTermLength;
      this.form.termUnit = this.depProd.termUnit;
      this.form.autoDormant = this.depProd.autoDormant;
      this.form.autoDormantAfter = this.depProd.autoDormantAfter;
      this.form.allowArbitaryFees = this.depProd.allowArbitaryFees;
      this.form.showInactiveFees = this.depProd.showInactiveFees;
      this.form.availablity = this.depProd.availability;
      this.form.forAllBranches = this.depProd.forAllBranches;
      if (this.form.forAllBranches == true) {
        this.branches = false;
      } else {
        this.branches = true;
      }
      this.form.branches = this.depProd.branches;
      this.initialBranches = this.depProd.branches;
      this.id = this.depProd.savingproductkey;
      this.form.id = this.depProd.id;
      this.form.fees = this.depProd.fees;
    },
    closeCopyProductModal() {
      this.checkProductType();
      this.showCopyProductModal = !this.showCopyProductModal;
      this.resetCopyProductForm();
    },
    copyDepositProduct(allDeposit) {
      this.depProd = {};
      let id = allDeposit.id;
      ApiService.get(`DepositAdministration/GetDepositProductByProductId/${id}`)
        .then((resp) => {
          this.loading = false;
          this.depProd = resp.data.data;
          this.fillCopyProduct();
          this.showCopyProductModal = !this.showCopyProductModal;
        })
        .catch((e) => {
          this.loading = false;
          this.alertError(e.response.data.message);
        });
    },
    getProductByID(allDeposit) {
      // this.form.showInactiveFees = false;
      this.showEditModal = !this.showEditModal;
      this.depProd = [];
      this.depProdState = false;
      let id = allDeposit.id;
      ApiService.get(`DepositAdministration/GetDepositProductByProductId/${id}`)
        .then((resp) => {
          this.depProd = resp.data.data;
          this.depProdState = resp.data.message;

          this.form.productName = this.depProd.productName;
          this.form.productID = this.depProd.productID;
          this.form.productCategory = this.depProd.productCategory;
          this.form.productType = this.depProd.productType;
          if (
            this.form.productType === "Saving_Plan" ||
            this.form.productType === "Saving Plan"
          ) {
            this.productType = true;
            this.depProduct = true;
            this.openBalance = false;
            this.maxDepo = false;
          } else if (
            this.form.productType === "Savings_Account" ||
            this.form.productType === "Saving Account"
          ) {
            this.depProduct = true;
            this.productType = false;
            this.maxDepo = true;
            this.openBalance = false;
          } else if (
            this.form.productType === "Fixed_Deposit" ||
            this.form.productType === "Fixed Deposit"
          ) {
            this.depProduct = false;
            this.productType = true;
            this.openBalance = true;
            this.maxDepo = false;
          } else {
            this.productType = false;
            this.depProduct = false;
            this.openBalance = false;
            this.maxDepo = false;
          }
          this.form.interestPerDays = this.depProd.interestPerDays;
          this.form.maxInterestCalculationBalance =
            this.depProd.maxInterestCalculationBalance;
          this.form.defaultDepositAmount = this.depProd.defaultDepositAmount;
          this.form.maxWithdrawalAmount = this.depProd.maxWithdrawalAmount;
          this.form.dailyWithdrawalLimit = this.depProd.dailyWithdrawalLimit;
          this.form.defaultOpeningBalance = this.depProd.defaultOpeningBalance;
          this.form.minOpeningBalance = this.depProd.minOpeningBalance;
          this.form.maxOpeningBalance = this.depProd.maxOpeningBalance;
          this.form.productState = this.depProd.productState;
          this.form.productDescription = this.depProd.productDescription;
          this.form.idType = this.depProd.idType;
          if (this.form.idType === "Random Pattern") {
            this.template = true;
          }
          this.form.idTemplate = this.depProd.idTemplate;
          this.form.currency = this.depProd.currency;
          this.form.interestPaidIntoAccount =
            this.depProd.interestPaidIntoAccount;
          this.form.interestRateTerms = this.depProd.interestRateTerms;
          this.form.interestChargeMethod = this.depProd.interestChargeMethod;
          if (this.form.interestChargeMethod === "% Per X Days") {
            this.days = true;
          } else {
            this.days = false;
          }
          this.form.calculationAccountBalance =
            this.depProd.calculationAccountBalance;
          if (
            this.form.calculationAccountBalance === "End Of The Day Balance"
          ) {
            this.balanceCalc = true;
          } else {
            this.balanceCalc = false;
          }
          this.form.interestPaymentPeriod = this.depProd.interestPaymentPeriod;
          if (this.form.interestPaymentPeriod === "Every Month") {
            this.monthly = true;
          } else {
            this.monthly = false;
          }
          this.form.interestDaysInYears = this.depProd.interestDaysInYears;
          this.form.defaultInterestRateConstraints =
            this.depProd.defaultInterestRateConstraints;
          this.form.minInterestRateConstraints =
            this.depProd.minInterestRateConstraints;
          this.form.maxInterestRateConstraints =
            this.depProd.maxInterestRateConstraints;
          this.form.tiers = this.depProd.tiers;
          if (
            this.form.interestRateTerms === "Fixed" ||
            this.form.interestRateTerms === "FIXED"
          ) {
            this.fixedTerm = true;
            this.balancedTier = false;
            this.periodTiered = false;
          } else if (
            this.form.interestRateTerms === "Tier per balance" ||
            this.depProd.interestRateTerms === "Tier per balance"
          ) {
            this.fixedTerm = false;
            this.balancedTier = true;
            this.periodTiered = false;
            this.form.balanceTiers = this.depProd.tiers;
          } else if (
            this.form.interestRateTerms === "Tier per period" ||
            this.depProd.interestRateTerms === "Tier per period"
          ) {
            this.fixedTerm = false;
            this.balancedTier = false;
            this.periodTiered = true;
            this.form.periodTiers = this.depProd.tiers;
          } else {
            this.fixedTerm = false;
            this.balancedTier = false;
            this.periodTiered = false;
          }
          this.form.defaultTermLength = this.depProd.defaultTermLength;
          this.form.minTermLength = this.depProd.minTermLength;
          this.form.maxTermLength = this.depProd.maxTermLength;
          this.form.termUnit = this.depProd.termUnit;
          this.form.autoDormant = this.depProd.autoDormant;
          this.form.autoDormantAfter = this.depProd.autoDormantAfter;
          this.form.allowArbitaryFees = this.depProd.allowArbitaryFees;
          this.form.showInactiveFees = true;
          this.form.availablity = this.depProd.availability;
          this.form.forAllBranches = this.depProd.forAllBranches;
          if (this.form.forAllBranches == true) {
            this.branches = false;
          } else {
            this.branches = true;
          }
          this.form.branches = this.depProd.branches;
          this.initialBranches = this.depProd.branches;
          this.id = this.depProd.savingproductkey;
          this.form.id = this.depProd.id;
          this.form.fees = this.depProd.fees;
          this.showOnlyInactive("edit");
        })
        .catch((e) => {
          this.loading = false;
          this.alertError(e.response.data.message);
        })
        .finally(() => (this.loading = false));
    },
    onUpdate() {
      // this.showEditModal = !this.showEditModal;
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      } else {
        this.loading = true;

        let productName = this.form.productName;
        let productID = this.form.productID;
        let productCategory = this.form.productCategory;
        let productType = this.form.productType;
        let interestPerDays = this.form.interestPerDays
          ? this.form.interestPerDays
          : 0;
        let maxInterestCalculationBalance = this.form
          .maxInterestCalculationBalance
          ? this.form.maxInterestCalculationBalance
          : 0;
        let defaultDepositAmount = this.form.defaultDepositAmount
          ? this.form.defaultDepositAmount
          : 0;
        let maxWithdrawalAmount = this.form.maxWithdrawalAmount
          ? this.form.maxWithdrawalAmount
          : 0;
        let dailyWithdrawalLimit = this.form.dailyWithdrawalLimit
          ? this.form.dailyWithdrawalLimit
          : 0;
        let defaultOpeningBalance = this.form.defaultOpeningBalance
          ? this.form.defaultOpeningBalance
          : 0;
        let minOpeningBalance = this.form.minOpeningBalance
          ? this.form.minOpeningBalance
          : 0;
        let maxOpeningBalance = this.form.maxOpeningBalance
          ? this.form.maxOpeningBalance
          : 0;
        let productState = this.form.productState;
        let productDescription = this.form.productDescription
          ? this.form.productDescription
          : "";
        let idType = this.form.idType;
        let idTemplate = this.form.idTemplate;
        let currency = this.form.currency;
        let interestPaidIntoAccount = this.form.interestPaidIntoAccount;
        let branches = this.form.branches;
        let forAllBranches = this.form.forAllBranches;
        let interestRateTerms = this.form.interestRateTerms
          ? this.form.interestRateTerms
          : "";
        let interestChargeMethod = this.form.interestChargeMethod
          ? this.form.interestChargeMethod
          : "";
        let calculationAccountBalance = this.form.calculationAccountBalance
          ? this.form.calculationAccountBalance
          : "";
        let interestPaymentPeriod = this.form.interestPaymentPeriod
          ? this.form.interestPaymentPeriod
          : "";
        let interestDaysInYears = this.form.interestDaysInYears
          ? this.form.interestDaysInYears
          : "";
        let defaultInterestRateConstraints = this.form
          .defaultInterestRateConstraints
          ? this.form.defaultInterestRateConstraints
          : 0;
        let minInterestRateConstraints = this.form.minInterestRateConstraints
          ? this.form.minInterestRateConstraints
          : 0;
        let maxInterestRateConstraints = this.form.maxInterestRateConstraints
          ? this.form.maxInterestRateConstraints
          : 0;
        let balanceTiers = this.form.balanceTiers;
        let periodTiers = this.form.periodTiers;
        let defaultTermLength = this.form.defaultTermLength
          ? this.form.defaultTermLength
          : 0;
        let minTermLength = this.form.minTermLength
          ? this.form.minTermLength
          : 0;
        let maxTermLength = this.form.maxTermLength
          ? this.form.maxTermLength
          : 0;
        let termUnit = this.form.termUnit ? this.form.termUnit : "";
        let autoDormant = this.form.autoDormant;
        let autoDormantAfter = this.form.autoDormantAfter
          ? this.form.autoDormantAfter
          : 0;
        let allowArbitaryFees = this.form.allowArbitaryFees;
        let showInactiveFees = this.form.showInactiveFees;
        let fees = this.form.fees;
        let availablity = this.form.availablity;
        let createdBy = this.form.createdBy;
        let modifiedBy = this.form.modifiedBy;
        let active = this.form.productState;
        let savingproductkey = this.form.id;
        this.$store
          .dispatch(UPDATE_DEPOSIT_PRODUCT, {
            savingproductkey,
            productName,
            productID,
            productCategory,
            productType,
            interestPerDays,
            maxInterestCalculationBalance,
            defaultDepositAmount,
            maxWithdrawalAmount,
            dailyWithdrawalLimit,
            defaultOpeningBalance,
            minOpeningBalance,
            maxOpeningBalance,
            productState,
            productDescription,
            idType,
            idTemplate,
            currency,
            interestPaidIntoAccount,
            interestRateTerms,
            interestChargeMethod,
            calculationAccountBalance,
            interestPaymentPeriod,
            interestDaysInYears,
            defaultInterestRateConstraints,
            minInterestRateConstraints,
            maxInterestRateConstraints,
            balanceTiers,
            periodTiers,
            defaultTermLength,
            minTermLength,
            maxTermLength,
            termUnit,
            autoDormant,
            autoDormantAfter,
            allowArbitaryFees,
            showInactiveFees,
            fees,
            branches,
            forAllBranches,
            availablity,
            createdBy,
            modifiedBy,
            active,
          })
          .then(() => {
            this.loading = false;
            let desc = "Product succesfully updated!";
            this.alertSuccess(desc);
            this.cancel();
            this.closeConfirmUpdate();
            this.getDeposits("reload");
          })
          .catch((e) => {
            this.loading = false;
            this.alertError(e.response.data.message);
          })
          .finally(() => {
            this.loading = false;
            this.getDeposits();
            this.showConfirmUpdate = false;
            // location.reload();
          });
      }
    },
    async getBranches() {
      try {
        this.options = [];
        this.isLoading = true;
        const res = await branchesService.get(this.fetchModel);
        // res.data.data.items.forEach((object) => {
        //   this.allBranches.push(object)
        // })
        this.options = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    openCreateAccountModal(allDeposit) {
      this.showCreateAccountModal = true;
      this.account.depositProduct = allDeposit.productName;
      this.depProd = [];
      let id = allDeposit.id;
      ApiService.get(`DepositAdministration/GetDepositProductByProductId/${id}`)
        .then((resp) => {
          this.depProd = resp.data.data;
          this.account.depositSetting = this.depProd;
        })
        .catch((e) => {
          this.alertError(e.response.data.message);
        });
    },
    closeCreateAccountModal() {
      this.showCreateAccountModal = false;
      this.account.depositProduct = [];
      this.nextDepositForm = false;
      this.depositForm = true;
      this.prefillForm();
    },
    limitText(count) {
      return `and ${count} other customers`;
    },
    apifetch(query) {
      if (this.account.accountType === "Customers") {
        GeneralApiService.get(`Customer/search/${query}`).then((resp) => {
          this.customers = resp.data.data.items;
        });
      } else if (this.account.accountType === "Groups") {
        GeneralApiService.get(`Customer/search/${query}/group`).then((resp) => {
          this.customers = resp.data.data.groups;
          this.groups = resp.data.data.customers;
        });
      }
    },
    clearAll() {
      this.selectedCustomer = "";
    },
    depositAccountFormCreate() {
      this.depositForm = false;
      this.nextDepositForm = true;

      if (
        this.account.depositSetting.interestRateTerms === "Tier Per Balance" ||
        this.account.depositSetting.interestRateTerms === "Tier per balance"
      ) {
        this.tierBalance = true;
      } else if (this.account.depositSetting.interestRateTerms === "Fixed") {
        this.fixedTermR = true;
      } else if (
        this.account.depositSetting.interestRateTerms === "Tier Per Period" ||
        this.account.depositSetting.interestRateTerms === "Tier per period"
      ) {
        this.tierPeriod = true;
      }
      this.deposit.accountHolderKey =
        this.selectedCustomer.customerKey || this.selectedCustomer.groupKey;
      this.deposit.accountHolderType = this.account.accountType;
      this.deposit.accountType = this.account.depositSetting.productType;
      this.deposit.assignedBranchKey = this.selectedCustomer.assignedBranchKey;
      this.deposit.assignedCenterKey = this.selectedCustomer.assignedCentreKey;
      this.deposit.assignedUserKey = "2376c9169fdd49d5a08be6ac4890608f";
      this.deposit.currencyCode = this.account.depositSetting.currency;
      this.deposit.interestSettingsKey =
        this.account.depositSetting.interestSavingsKey;
      this.deposit.savingsAccountName = this.account.depositProduct;
      this.deposit.savingsProductKey = this.account.depositSetting.id;
      this.deposit.defaultDepositAmount =
        this.account.depositSetting.defaultDepositAmount;
      this.deposit.maxWithdrawalAmount =
        this.account.depositSetting.maxWithdrawalAmount;
      this.deposit.dailyWithdrawalLimit = 
        this.account.depositSetting.dailyWithdrawalLimit;
    },
    createDepositAccount(e) {
      if (this.account.depositSetting.interestRateTerms !== "Fixed") {
        this.loading = true;
        ApiService.post("DepositAccount/CreateDepositAccount", this.deposit)
          .then((resp) => {
            this.loading = false;
            this.alertSuccess(resp.data.message);
            this.closeCreateAccountModal();
            this.getDeposits("reload");
          })
          .catch((e) => {
            this.loading = false;
            this.alertError(e.response.data.message);
          })
          .finally(() => {
            this.loading = false;
            this.getDeposits();
          });
      } else {
        this.interestError = [];
        if (
          this.deposit.interestRate <
            this.account.depositSetting.minInterestRateConstraints ||
          this.deposit.interestRate >
            this.account.depositSetting.maxInterestRateConstraints
        ) {
          this.alertError(
            `Interest rate must be between ${this.account.depositSetting.minInterestRateConstraints} and ${this.account.depositSetting.maxInterestRateConstraints}`
          );
          this.interestError.push(
            `Interest must be between ${this.account.depositSetting.minInterestRateConstraints} and ${this.account.depositSetting.maxInterestRateConstraints}`
          );
        }
        if (!this.interestError.length) {
          this.loading = true;
          ApiService.post("DepositAccount/CreateDepositAccount", this.deposit)
            .then((resp) => {
              this.loading = false;
              this.alertSuccess(resp.data.message);
              this.closeCreateAccountModal();
              this.getDeposits("reload");
            })
            .catch((e) => {
              this.loading = false;
              this.alertError(e.response.data.message);
            })
            .finally(() => {
              this.loading = false;
              this.getDeposits();
            });
        }
      }
      e.preventDefault();
    },
    switchShowDeactivated() {
      this.deactivatedProduct = !this.deactivatedProduct;
      if (this.deactivatedProduct) {
        this.state = true;
      } else {
        this.state = false;
      }
      this.getDeposits();
    },
    filterByCategory(id, name) {
      this.filter_by_label = name + " <ion-icon name='caret-down-outline'/>";
      this.sort = id;
      this.$store.dispatch(GET_ALL_DEPOSITS, {
        pageindex: this.products.currentPage,
        pagesize: this.size,
        status: this.productState,
        sort: this.sort,
        search: this.searchProductName,
      });
    },
    getUpdatedDeposits(value) {
      this.size = value;
      this.getDeposits();
    },
    changePageIndex(value) {
      this.changeCurrentPage(value);
    },
    prefillForm() {
      this.form = {
        productName: "",
        productID: "",
        productCategory: "",
        productType: "",
        interestPerDays: 0,
        maxInterestCalculationBalance: 0,
        defaultDepositAmount: 0,
        maxWithdrawalAmount: 0,
        dailyWithdrawalLimit: 0,
        defaultOpeningBalance: "",
        minOpeningBalance: "",
        maxOpeningBalance: "",
        productState: false,
        productDescription: "",
        idType: "",
        idTemplate: "###@@",
        currency: "",
        interestPaidIntoAccount: false,
        interestRateTerms: "",
        interestChargeMethod: "",
        calculationAccountBalance: "",
        interestPaymentPeriod: "",
        interestDaysInYears: "",
        defaultInterestRateConstraints: 0,
        minInterestRateConstraints: 0,
        maxInterestRateConstraints: 0,
        balanceTiers: [],
        periodTiers: [],
        defaultTermLength: "",
        minTermLength: "",
        maxTermLength: "",
        termUnit: "",
        autoDormant: false,
        active: false,
        autoDormantAfter: "",
        allowArbitaryFees: false,
        showInactiveFees: true,
        fees: [],
        branches: [],
        forAllBranches: false,
        availablity: [],
        createdBy: "Jude",
        modifiedBy: "Jude",
        tiers: [],
      };
    },
  },
  computed: {
    ...mapGetters([
      "allProductTypes",
      "allproductcategory",
      "allCurrency",
      "allInterestCharge",
      "allInterestRate",
      "allIDTypes",
      "allDaysInYears",
      "allAccountBalanceCalculation",
      "allAccountInterests",
      "allMonths",
      "allFeeTypes",
      "allAppliedDates",
      "allProducts",
      "allAvailableProducts",
      "allErrors",
      "allDeposits",
      "depositProd",
      "alltermUnits",
    ]),
    ...mapState({
      products: (state) => state.deposit.getAllDeposits,
      user: (state) => state.auth.user,
    }),

    sortedCurrencies() {
      return this.allCurrency.data.items.slice().sort((a, b) => {
        let textA = a.currencyCode.toUpperCase();
        let textB = b.currencyCode.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    },

    userCanViewDepositProduct() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.Product
      );
      const { permissionsList } = account;
      return permissionsList.CanViewSavingsProduct;
    },

    userCanEditDepositProduct() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.Product
      );
      const { permissionsList } = account;
      return permissionsList.CanEditSavingsProduct;
    },

    userCanCreateProduct() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.Product
      );
      const { permissionsList } = account;
      return permissionsList.CanCreateSavingsProduct;
    },

    userCanDeleteProduct() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.Product
      );
      const { permissionsList } = account;
      return permissionsList.CanDeleteSavingsProduct;
    },

    userCanActivateProduct() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.Product
      );
      const { permissionsList } = account;
      return permissionsList.CanActivateSavingsProduct;
    },

    userCanDeactivateProduct() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.Product
      );
      const { permissionsList } = account;
      return permissionsList.CanDeactivateSavingsProduct;
    },

    filterProductByStatus() {
      return this.products.data;
    },
    isInputFilled() {
      return (
        this.selectedCustomer &&
        this.account.depositSetting &&
        this.account.accountType
      );
    },
  },
  created() {
    this.GET_ALL_PRODUCT_TYPE(),
    this.GET_ALL_PRODUCT_CATEGORY(),
    this.GET_ALL_CURRENCY(),
    this.GET_ALL_INTEREST_CHARGE(),
    this.GET_ALL_INTEREST_RATE(),
    this.GET_ALL_ID(),
    this.GET_DAYS_IN_YEARS(),
    this.GET_ACCOUNT_BALANCE_CALCULATION(),
    this.GET_ACCOUNT_INTEREST(),
    this.GET_MONTHS(),
    this.GET_FEE_TYPE(),
    this.GET_APPLIED_DATE_METHOD(),
    this.getDeposits(),
    this.GET_PRODUCT_AVAILABILITY();
    this.GET_TERM_UNIT();
    this.getBranches();
  },
  watch: {
    initialBranches: {
      handler(values) {
        if (values) {
          this.allBranches = this.options.filter((x) =>
            values.includes(x[this.trackBy])
          );
        }
      },
    },
    sort: function (val) {
      this.filter_by_label = val + " <ion-icon name='caret-down-outline'/>";
      this.$store.dispatch(GET_ALL_DEPOSITS, {
        pageindex: this.products.currentPage,
        pagesize: this.size,
        status: this.productState,
        sort: this.sort,
        search: this.searchProductName,
      });
    },
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
  mounted() {
    this.prefillForm();
  },
};
</script>

<style scoped>
@import "https://unpkg.com/vue-multiselect@2.1.0/dist/vue-multiselect.min.css";

.no-padding__right {
  padding-right: 0;
}
.page__body__header__button {
  color: #fff;
}
.page__body__header__button span {
  color: #fff;
}
.modal__accordion--toggle {
  padding: 2.75rem 0;
}
.form__span {
  display: block;
  font-size: 14px;
  color: black;
  font-weight: 600;
}
span.page__body__header__label {
  font-size: 12px;
  color: #808191;
  font-weight: 600;
  letter-spacing: 1.15%;
  line-height: 2;
}
.button--grey span {
  color: #1e1f20;
}
.error {
  margin-top: -3rem;
  margin-bottom: 2rem;
}
.error span {
  font-size: 11px;
}
button:disabled {
  opacity: 0.6;
  cursor: no-drop;
}
.fitrem {
  min-width: 15rem;
}
.small__p {
  padding: 4.5rem 1rem;
}
.select--page {
  height: 4rem;
  padding: 1rem 2.25rem 1rem 1.25rem;
  border: 1px solid #f58634;
}
</style>
